//React and third-parties
import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import cogoToast from 'cogo-toast';
import DatePicker, { registerLocale } from "react-datepicker";
import NumberFormat from 'react-number-format';
import es from "date-fns/locale/es";

// Context
import { Consumer } from '../../context';

// Hooks
import useExcel from '../../hooks/useExcel';
import useMuliSelectFormat, { getSelected } from '../../hooks/useMuliSelectFormat';
import useInformesParameters,
{ desgloseTipos, pagoEstadosTipos, vaciosTipos, loadTipos } from '../../pages/ConsultasYReportes/hooks/useInformesParameters';

// Components
import MultiSelect, { SelectModal } from '../selectores/MultiSelect';

// Helpers
import exportExcel from '../../helpers/exportExcel';
import { range } from 'lodash';
import moment from "moment-timezone";

registerLocale("es", es);

const excelDateFormat = "dd/mm/yyyy HH:mm:ss";

const cogoToastOptions = { position: "bottom-right", hideAfter: 5 };

const foliosFilterTypes = {
    empieza: "Empiezan con",
    termina: "Terminan con",
    incluye: "Incluyen"
};

const pageButtons = {
    none: 0,
    search: 1,
    filter: 2,
    tableSettings: 3
};

const months = moment().locale('es').localeData().months();

const setSectionsInfoInit = () => {
    return {
        height: undefined,
        search: { offset: undefined, disabled: undefined },
        filter: { offset: undefined, disabled: undefined },
        tableSettings: { offset: undefined, disabled: undefined }
    }
};

const detalladosExcelConfig = {
    excelColumnsName: "Vivienda,N° Folio,Producto,Mes,Importe,Descuento,Condonado,Recargos,Pago,Saldo",
    excelColumnsShow: "vivienda,num_folio,producto,mes_anyo,cargo,descuento,condonacion,recargo,pago,saldo",
    excelColumnsType: "string,string,string,string,money,money,money,money,money,money"
};

const concentradosExcelConfig = (headersInfo, desglose) => {

    const degloseSubColumnsNameString = desglose ? "Importe,Descuento,Condonado,Recargo,Pago" : "Total,Pago";
    const desgloseSubColumnsShowString = desglose ? "cargo,descuento,condonacion,recargo,pago" : "total,pago";
    const desgloseSubColumnsTypeString =  desglose ? "money,money,money,money,money" : "money,money";

    return {
        excelColumnsName: "Vivienda," + headersInfo
        .filter(({ disabled }) => !disabled).map(({ header }) => header).join(','),
        excelColumnsShow: "vivienda,productos",
        excelColumnsType: "string,object",
        excelSubColumnsName: degloseSubColumnsNameString,
        excelSubColumnsShow: desgloseSubColumnsShowString,
        excelSubColumnsType: desgloseSubColumnsTypeString,
        enabledHeadersIndeces: headersInfo.reduce((indeces, { disabled }, index) => {
            if(!disabled) indeces.push(index);
            return indeces;
        }, [])
    }
};

export function InformesControls(props) {

    const {
        title, context,
        excelData,
        onSizeChange,
        onInformes, onFiltersChanged, onTableSettingsChanged
    } = props;

    const { setDefaultExportFormat, setConcentradoExportFormat,
        generateDetalladosExcel, generateConcentradosExcel } = useExcel();

    const {
        infos,
        loadingInfos,
        filters,
        currentSearchFilters,
        setFilters, setSelectionFilters, setTableSettingsFilter,
        loadSubfraccionamientos, loadViviendas, loadProductosAndPrimerCargoFecha, 
        loadInformes, loadInformesMorosos, getExcelFilterValues
    } = useInformesParameters();

    const [buttonHovered, setButtonHovered] = useState(pageButtons.none);
    const [sectionsInfo, setSectionsInfo] = useState(setSectionsInfoInit);
    const [loadingExcel, setLoadingExcel] = useState(false);

    const controlsRef = useRef();
    const headersInfoRef = useRef();

    const years = range(filters.primerCargoFecha.getFullYear() || 2000, 2100, 1);

    if(sectionsInfo.height === undefined) {
        const controlsElement = controlsRef.current;
        const [headerElement, sectionsElement] = Array.from(controlsElement?.children || [null,null]);

        if(sectionsElement) {

            const sectionsChildren = Array.from(sectionsElement.children);
            const controlsSectionHeight = sectionsChildren.reduce((totalHeight, child) => totalHeight + child.clientHeight, 0)
            const headerHeight = headerElement.clientHeight;

            setSectionsInfo({
                height: controlsSectionHeight,
                search: { offset: 0 },
                filter: { offset: sectionsChildren[0].clientHeight },
                tableSettings: { offset: sectionsChildren[0].clientHeight + sectionsChildren[1].clientHeight }
            });

            onSizeChange && onSizeChange({ height: controlsSectionHeight + headerHeight });
        };
    }
    
    const requestSubfraccionamientos = useCallback(async (fraccionamiento) => {

        const { message, error } = await loadSubfraccionamientos(fraccionamiento);

        if(!error) message && cogoToast.warn(error, cogoToastOptions);

        else cogoToast.error(error, cogoToastOptions);

    }, [loadSubfraccionamientos]);

    const requestViviendas = useCallback(async (fraccionamiento, subfraccionamiento = 0) => {
        
        const { error } = await loadViviendas(fraccionamiento, subfraccionamiento);

        if(error) cogoToast.error(error, cogoToastOptions);

    }, [loadViviendas]);

    const requestProductos = useCallback(async (fraccionamiento) => {

        const { message, error } = await loadProductosAndPrimerCargoFecha(fraccionamiento);

        if(!error) message && cogoToast.warn(message, cogoToastOptions);

        else cogoToast.error(error, cogoToastOptions);

    }, [loadProductosAndPrimerCargoFecha]);

    useEffect(() => {

        const fraccionamiento = +(localStorage.getItem("frac") || 0);

        if(!fraccionamiento) return;

        requestSubfraccionamientos(fraccionamiento);
        requestViviendas(fraccionamiento);
        requestProductos(fraccionamiento);

    }, [requestSubfraccionamientos, requestViviendas, requestProductos]);

    const onExportExcel = (desglose) => {

        setLoadingExcel(true);

        /*
            Generar Excel
        */

        const title = desglose === 0 ? "Reporte Detallado" : "Reporte Concentrado";
        const excelConfig = desglose === 0 ?
            detalladosExcelConfig
            : concentradosExcelConfig(
                headersInfoRef.current, 
                filters.desglosarTotales);
        const usuario = context.user?.id?.usuario || "";
        const generateExcel = desglose === 0 ? 
            (data) => generateDetalladosExcel(data, getExcelFilterValues()) 
            : (data) => generateConcentradosExcel(data, getExcelFilterValues())
        const exportFormat = desglose === 0 ? setDefaultExportFormat : setConcentradoExportFormat;

        const { error, created } = exportExcel(
            excelData,
            title,
            context.fraccionamiento,
            usuario,
            excelDateFormat,
            false,
            generateExcel,
            exportFormat,
            excelConfig
        )

        if (error) cogoToast.error(error, {
            position: "bottom-right",
        });

        if (created) cogoToast.success("Excel generado", {
            position: "bottom-right",
        })
       
        setLoadingExcel(false);
    };

    const onSearchMorosos = async () => {

        const id_fraccionamiento = +(localStorage.getItem("frac") || 0);

        const { informes, selectedIds, morososFilters, message, error } = await loadInformesMorosos(id_fraccionamiento);

        if (!error) message && cogoToast.warn(message, cogoToastOptions)

        else cogoToast.error(error, cogoToastOptions);

        onSectionsChanged();

        informes && onInformes && onInformes(informes, { ...selectedIds, ...morososFilters });
    };

    const onSearchInformes = async () => {

        const id_fraccionamiento = +(localStorage.getItem("frac") || 0);

        const { informes, selectedIds, desglose, headersInfo, message, error } = await loadInformes(id_fraccionamiento);

        if (!error) message && cogoToast.warn(message, cogoToastOptions)

        else cogoToast.error(error, cogoToastOptions);

        if(headersInfo) headersInfoRef.current = headersInfo;

        onSectionsChanged();

        console.log("INFORMES: ", informes);

        informes && onInformes && onInformes(informes, { ...selectedIds, desglose, headersInfo });
    };

    const onFilterParametersChanged = (type, value) => {

        const values = setFilters(type, value);
        onFiltersChanged && onFiltersChanged(values);
    };

    const onSelectionFilterParametersChanged = (type, value, added = true) => {

        const values = setSelectionFilters(type, value, added);
        if(values.headersInfo) headersInfoRef.current = values.headersInfo;

        onFiltersChanged && onFiltersChanged(values);
    };

    const onSettingsChanged = (type, value) => {
        setTableSettingsFilter(type, value);
        onTableSettingsChanged && onTableSettingsChanged({ [type]: value })
    };

    const setSectionsHeight = (sections) => {

        let sectionsHeight = 0;

        const setInfo = (info, offset) => {
            if(!info.disabled) {
                info.offset = sectionsHeight;
                sectionsHeight += offset;
            } else {
                info.offset = -offset
            }
        };

        const [headerElement, sectionsElement] = controlsRef.current.children;
        const [searchSection, filterSection, settingsSection] = sectionsElement.children;

        setInfo(sections['search'], searchSection.clientHeight);
        setInfo(sections['filter'], filterSection.clientHeight);
        setInfo(sections['tableSettings'], settingsSection.clientHeight);

        sections.height = sectionsHeight;

        onSizeChange && onSizeChange({ height: headerElement.clientHeight + sectionsHeight });
    };

    const onSectionsChanged = () => setSectionsInfo(prevSections => {

        const newInfo = { ...prevSections };

        setSectionsHeight(newInfo);

        return newInfo;
    })

    const onDisablingSection = (section) => setSectionsInfo(prevSections => {

        const newInfo = { ...prevSections };

        newInfo[section] = newInfo[section].disabled ?
            { offset: newInfo[section].offset }
            : newInfo[section] = { offset: 0, disabled: true };

        setSectionsHeight(newInfo);
        
        return newInfo;
    });

    return (
        <div id="informes-controls"
            ref={controlsRef}
            className="container container-control column full"
            style={{ transition: "all 500ms ease-in-out", zIndex: 2 }}
        >
            <div className="navigation align-center"
                style={{ backgroundColor: "#FFF", zIndex: 2, paddingTop: 32, paddingBottom: 8 }}
            >
                <div style={{ minHeight: 40 }} className="justify-start">
                    <h3 className="weight-semi">{title}</h3>
                </div>
                <div className="buttons-div justify-end" style={{ gap: 8 }}>
                    <ControlButton 
                        type="search"
                        disabled={sectionsInfo.search.disabled}
                        onPointerEnter={() => setButtonHovered(pageButtons.search)}
                        onPointerLeave={() => setButtonHovered(pageButtons.none)}
                        onClick={() => onDisablingSection('search')}
                    />
                    <ControlButton
                        type="filter"
                        disabled={sectionsInfo.filter.disabled}
                        onPointerEnter={() => setButtonHovered(pageButtons.filter)}
                        onPointerLeave={() => setButtonHovered(pageButtons.none)}
                        onClick={() => onDisablingSection('filter')}
                    />
                    <ControlButton
                        type="tableSettings"
                        disabled={sectionsInfo.tableSettings.disabled}
                        onPointerEnter={() => setButtonHovered(pageButtons.tableSettings)}
                        onPointerLeave={() => setButtonHovered(pageButtons.none)}
                        onClick={() => onDisablingSection('tableSettings')}
                    />
                    <ExcelButton
                        data={excelData || "asd"}
                        loading={loadingExcel}
                        disabled={excelData ? excelData.length === 0 : true}
                        onClick={() => onExportExcel(currentSearchFilters.desglose)}
                    />
                </div>
            </div>
            <div
                id="informes-control-sections"
                style={{
                    display: "flex", flexDirection: "column", width: "100%", backgroundColor: "#FFF",
                    position: "relative",
                    zIndex: 1,
                    height: sectionsInfo.height,
                    transition: "all 300ms ease-in-out",
                    overflow: "hidden"
                }}
            >
                <ControlSection
                    highlighted={buttonHovered === pageButtons.search}
                    disabled={sectionsInfo['search'].disabled}
                    offset={sectionsInfo['search'].offset}
                    zIndex={2}
                >
                    <FilterCard title="Desglose" style={{ width: "20%" }}>
                        <select
                            className="input input-select"
                            style={{ padding: 7, width: "100%" }}
                            name="desglose"
                            disabled={loadingInfos.informes !== loadTipos.No}
                            value={filters.desglose}
                            onChange={(e) => setFilters('desglose', +e.target.value)}
                        >
                            {Object.keys(desgloseTipos).map((estado, index) => (
                                <option key={index} value={index}>
                                    {estado}
                                </option>
                            ))}
                        </select>
                    </FilterCard>
                    <FilterCard title="Periodo de Cargos" style={{ width: "30%" }}>
                        <FechaCargoDatePicker
                            cargosFechas={filters.cargosFechas}
                            years={years}
                            disabled={loadingInfos.informes !== loadTipos.No}
                            onCargoFechasChange={(e) => setFilters('cargosFechas', e)}
                        />
                    </FilterCard>
                    <SearchButton
                        title={"Buscar"}
                        iconName={"fas fa-search"}
                        loading={loadingInfos.informes === loadTipos.Default}
                        onClick={loadingInfos.informes === loadTipos.No ? () => onSearchInformes() : undefined}
                    />
                    <SearchButton
                        title={"Morosos"}
                        iconName={"fas fa-hand-holding-usd"}
                        loading={loadingInfos.informes === loadTipos.Morosos}
                        onClick={loadingInfos.informes === loadTipos.No ? () => onSearchMorosos() : undefined}
                    />
                </ControlSection>
                <ControlSection
                    highlighted={buttonHovered === pageButtons.filter}
                    disabled={sectionsInfo['filter'].disabled}
                    offset={sectionsInfo['filter'].offset}
                    zIndex={1}
                >
                    <FilterCard title="Subfraccionamientos" style={{ width: "49%" }} >
                        <MultiSelect
                            style={{ width: "100%" }}
                            options={infos.subfraccionamientos.selectFormat}
                            onSelected={(estadosActivos) => onSelectionFilterParametersChanged('subsIds', estadosActivos, true)}
                            onRemoved={(estadosActivos) => onSelectionFilterParametersChanged('subsIds', estadosActivos, false)}
                        />
                    </FilterCard>
                    <FilterCard title="Viviendas" style={{ width: "49%" }}>
                        <MultiSelect
                            style={{ width: "100%" }}
                            options={infos.viviendas.selectFormat}
                            onSelected={(estadosActivos) => onSelectionFilterParametersChanged('vivsIds', estadosActivos, true)}
                            onRemoved={(estadosActivos) => onSelectionFilterParametersChanged('vivsIds', estadosActivos, false)}
                        />
                    </FilterCard>
                    <FilterCard title="Productos" style={{ width: "49%" }}>
                        <MultiSelect
                            style={{ width: "100%" }}
                            options={infos.productos.selectFormat}
                            onSelected={(estadosActivos) => onSelectionFilterParametersChanged('prodsIds', estadosActivos, true)}
                            onRemoved={(estadosActivos) => onSelectionFilterParametersChanged('prodsIds', estadosActivos, false)}
                        />
                    </FilterCard>
                    <FilterCard 
                        title="Folios"
                        style={{
                            display: currentSearchFilters.desglose === 0 ? "flex" : "none", width: "49%"
                        }}
                    >
                        <FoliosSelect
                            style={{ width: "100%" }}
                            folios={infos.folios.selectFormat}
                            onFoliosSelected={(folios) => onSelectionFilterParametersChanged('foliosIds', folios)}
                        />
                    </FilterCard>
                    <FilterCard 
                        title="Fecha de Pagos"
                        style={{
                            display: currentSearchFilters.desglose === 0 ? "flex" : "none", width: "32%"
                        }}
                    >
                        <FechaPagoDatePicker 
                            pagosFechas={filters.pagosFechas}
                            years={years}
                            disabled={filters.pagoEstado === 2}
                            onPagoFechasChange={(e) => onFilterParametersChanged('pagosFechas', e)}
                        />
                    </FilterCard>
                    <FilterCard 
                        title="Estado Pago"
                        style={{
                            display: currentSearchFilters.desglose === 0 ? "flex" : "none", width: "32%"
                        }}
                    >
                        <select
                            className="input input-select"
                            style={{ padding: 7, width: "100%" }}
                            name="pagoEstados"
                            value={filters.pagoEstado}
                            onChange={(e) => onFilterParametersChanged('pagoEstado', +e.target.value)}
                        >
                            {pagoEstadosTipos.map((estado, index) => (
                                <option key={index} value={index}>
                                    {estado}
                                </option>
                            ))}
                        </select>
                    </FilterCard>
                    <FilterCard 
                        title="Vacíos"
                        style={{
                            display: currentSearchFilters.desglose === 1 ? "flex" : "none", width: "32%"
                        }}                   
                    >
                        <select
                            className="input input-select"
                            style={{ padding: 7, width: "100%", colorRendering: "#F00" }}
                            name="importesVacios"
                            color='#F00'
                            value={filters.importesVacios}
                            onChange={(e) => onFilterParametersChanged('vacios', +e.target.value)}
                        >
                            {vaciosTipos.map((estado, index) => (
                                <option key={index} value={index}>
                                    {estado}
                                </option>
                            ))}
                        </select>
                    </FilterCard>
                </ControlSection>
                <ControlSection
                    highlighted={buttonHovered === pageButtons.tableSettings}
                    disabled={sectionsInfo['tableSettings'].disabled}
                    offset={sectionsInfo['tableSettings'].offset}
                    zIndex={0}
                >
                    <FilterCard title="Anclar Filas Totales"  >
                        <button
                            type='button'
                            className="container-radio"
                            onClick={() => onSettingsChanged('anclarFilas', !filters.anclarFilas)}
                            style={{ height: 20 }}
                        >
                            <input
                                type="radio"
                                name="informes-AFT"
                                readOnly
                                checked={filters.anclarFilas}
                                disabled={false}
                            />
                            <span className="checkmarks"></span>
                        </button>
                    </FilterCard>
                    <FilterCard title="Anclar Columnas Totales">
                        <button
                            type='button'
                            className="container-radio"
                            onClick={() => onSettingsChanged('anclarColumnas', !filters.anclarColumnas)}
                            disabled={currentSearchFilters.desglose === 0}
                            style={{ height: 20 }}
                        >
                            <input
                                type="radio"
                                name="informes-ACT"
                                readOnly
                                checked={filters.anclarColumnas}
                                disabled={currentSearchFilters.desglose === 0}
                            />
                            <span className="checkmarks"></span>
                        </button>
                    </FilterCard>
                    <FilterCard title="Desglosar Totales" >
                        <button
                            type='button'
                            className="container-radio"
                            onClick={() => onSettingsChanged('desglosarTotales', !filters.desglosarTotales)}
                            disabled={currentSearchFilters.desglose === 0}
                            style={{ height: 20 }}
                        >
                            <input
                                type="radio"
                                name="informes-DT"
                                readOnly
                                checked={filters.desglosarTotales}
                                disabled={currentSearchFilters.desglose === 0}
                            />
                            <span className="checkmarks"></span>
                        </button>
                    </FilterCard>
                </ControlSection>
            </div>
        </div>
    )
};

export default Consumer(InformesControls);

const ControlSection = (props) => {

    const { children, highlighted, disabled, offset, zIndex } = props;

    const topTransition = "top 300ms ease-in-out, ";
    const backgroundTransition = !disabled ? "background-color 300ms ease-in-out" : "background-color 0ms";
    
    return (
        <div
            className="row justify-start align-center"
            style={{
                transition: topTransition + backgroundTransition,
                position: "absolute",
                top: offset,
                zIndex: disabled ? 0 : zIndex,
                flexWrap: "wrap", columnGap: "2%", rowGap: 8,
                borderRadius: 5, padding: 4,
                backgroundColor: highlighted && !disabled ? "#DEDEDE" : "#FFF",
            }}
        >
            {children}
        </div>
    );
};

const PortalDatePicker = (props) => {

    const datePickerRef = useRef();

    const portalContainer = ({ className, children }) => {

        return createPortal(
            <div
                className={className}
                style={{ position: 'absolute', width: "100%" }}
            >
                {children}
            </div>
            ,document.body
        );
    };
        
    return (
        <DatePicker
            ref={datePickerRef}
            className="input full input-filter-datepicker-full"
            placeholderText='Seleccionar fecha'
            selectsRange
            locale="es"
            dateFormat="dd/MM/yyyy"
            popperContainer={portalContainer}
            {...props}
        />
    )
};

const FechaCargoDatePicker = (props) => {

    const { cargosFechas, years, disabled, onCargoFechasChange } = props;

    const minDate = new Date(years[0], 0, 1);

    return (
        <PortalDatePicker
            selected={cargosFechas.desde}
            startDate={cargosFechas.desde}
            endDate={cargosFechas.hasta}
            dateFormat="MMM yyyy"
            showMonthYearPicker={true}
            minDate={minDate}
            disabled={disabled}
            onChange={onCargoFechasChange}
            renderCustomHeader={({
                date, changeYear, increaseYear, decreaseYear,
                prevMonthButtonDisabled, nextMonthButtonDisabled
            }) => {

                const currentYear = new Date(date).getFullYear();

                return <div
                    style={{
                        paddingBottom: 5, 
                        display: "flex",
                        position: "relative",
                        justifyContent: "center",
                        alignItems: "center",
                        columnGap: 8
                    }}
                >
                    <button
                        className="react-datepicker__navigation react-datepicker__navigation--previous"
                        style={{ top: 4 }}
                        onClick={decreaseYear}
                        disabled={prevMonthButtonDisabled}>
                        {"<"}
                    </button>
                    <div className='row'>
                        <b style={{ fontSize: 17.6 }}>{currentYear}</b>
                        <select
                            style={{ backgroundColor: "transparent", fontSize: 0, fontWeight: 700 }}
                            value={currentYear}
                            onChange={({ target: { value } }) => changeYear(value)}
                        >
                            {years.map((option) => (
                                <option key={option} value={option} >
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button
                        className="react-datepicker__navigation react-datepicker__navigation--next"
                        style={{ top: 4 }}
                        onClick={increaseYear}
                        disabled={nextMonthButtonDisabled}
                    >
                        {">"}
                    </button>
                </div>
            }}
        />
    )
};

const FechaPagoDatePicker = (props) => {

    const { pagosFechas, years, disabled, onPagoFechasChange } = props;

    const minDate = new Date(years[0], 0, 1);

    return (
        <PortalDatePicker
            selected={pagosFechas.desde}
            startDate={pagosFechas.desde}
            endDate={pagosFechas.hasta}
            dateFormat="d MMM yyyy"
            disabled={disabled}
            minDate={minDate}
            onChange={onPagoFechasChange}
            renderCustomHeader={({
                date, changeYear, changeMonth,
                decreaseMonth, increaseMonth,
                prevMonthButtonDisabled, nextMonthButtonDisabled
            }) => {

                const currentMonth = months[new Date(date).getMonth()];
                const currentYear = new Date(date).getFullYear();

                return <div
                    style={{
                        display: "flex",
                        position: "relative",
                        justifyContent: "center",
                        alignItems: "center",
                        columnGap: 8
                    }}
                >
                    <button
                        className="react-datepicker__navigation react-datepicker__navigation--previous"
                        style={{ top: 4 }}
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}>
                        {"<"}
                    </button>
                    <div className='row'>
                        <b style={{ fontSize: 17.6 }}>{currentMonth}</b>
                        <select
                            style={{ backgroundColor: "transparent", fontSize: 0, fontWeight: 700 }}
                            value={currentMonth}
                            onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                            }
                        >
                            {months.map((option) => (
                                <option key={option} value={option} style={{ fontSize: 56 }}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='row'>
                        <b style={{ fontSize: 17.6 }}>{currentYear}</b>
                        <select
                            style={{ backgroundColor: "transparent", fontSize: 0, fontWeight: 700 }}
                            value={currentYear}
                            onChange={({ target: { value } }) => changeYear(value)}
                        >
                            {years.map((option) => (
                                <option key={option} value={option} >
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button
                        className="react-datepicker__navigation react-datepicker__navigation--next"
                        style={{ top: 4 }}
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                    >
                        {">"}
                    </button>
                </div>
            }}
        />
    )
};

const FilterCard = (props) => {

    const { title, children, style } = props;

    return (
        <div  style={{ display: "flex", flexDirection: "row", alignItems: "center", ...style }}>
            <h5 className="color-black" style={{ marginRight: 8, whiteSpace: "nowrap" }}>{title}</h5>
            {children}
        </div>
    );
};

const FoliosSelect = (props) => {

    const { folios, disabled = false, style, onFoliosSelected } = props;

    const [filterType, setFilterType] = useState(foliosFilterTypes.empieza)
    const [folio, setFolio] = useState("");
    const [openFoliosSelect, setOpenFoliosSelect] = useState(false);
    const [modalLayout, setModalLayout] = useState({ width: 0, height: 0, top: 0, left: 0 });

    const { 
        filteredOptions, allFilteredSelectedOptions,
        searchFilter, setSearchFilter,
        setOptions, getFilteredOptionsInfo,
        selectOption, selectAllOptions 
    } = useMuliSelectFormat([], 0)

    const elementRef = useRef();
    const modalRef = useRef();
    const inputRef = useRef();

    const hasSearch = folio !== "";

    useLayoutEffect(() => {
      
        if(openFoliosSelect) {

            const inputElement = elementRef.current.getElementsByClassName('folios-input')[0];
            const bounds =  inputElement.getBoundingClientRect();

            setModalLayout({ 
                width: bounds.width, 
                height: bounds.height + 2, 
                top: bounds.top,
                left: bounds.left
            });
        }

    }, [openFoliosSelect]);

    useEffect(() => {

        if (!openFoliosSelect && !hasSearch) return;

        const onPointerDown = (e) => {

            const containsChildren = elementRef.current.contains(e.target) 
            || (openFoliosSelect && modalRef.current.contains(e.target));

            if (!containsChildren) {
                setOpenFoliosSelect(false);
                setFolio("");
                setSearchFilter("");
            }
        };

        document.addEventListener('pointerdown', onPointerDown);

        return () => document.removeEventListener('pointerdown', onPointerDown);

    }, [openFoliosSelect, hasSearch, setSearchFilter]);

    const onKeyUp = ({ key }) => {

        if(key === "Escape") {
            setOpenFoliosSelect(false);
            setFolio("");
            return;
        }

        if(key === "Enter" && enableButton && !enableRemove) {
            onAddFolios();
        }
    };

    const onPointerDown = () => {

        if(filteredOptions.length > 0) setOpenFoliosSelect(true);
    };

    const onInputPointerDown = (e) => {

        if(e.target.tagName === "INPUT") return;

        e.preventDefault();
        inputRef.current.focus();
    };

    const onNumberFormatChange = (values) => {

        if(openFoliosSelect) {
            setSearchFilter(values.value.trim())
        } else {
            setFolio(values.value.trim())
        }
    };

    const onAddFolios = () => {

        setOptions(filteredFolios.map((option) => { return { ...option }}));
        setOpenFoliosSelect(true);
        setFolio("");

        const newOptionsSelected = getSelected(filteredFolios);
        onFoliosSelected && onFoliosSelected(newOptionsSelected);
    };

    const onRemoveFolios = () => {

        setOptions([]);
        setOpenFoliosSelect(false);
        setSearchFilter("");

        const newOptionsSelected = getSelected(folios);
        onFoliosSelected && onFoliosSelected(newOptionsSelected);
    };

    const onSelectOption = (index) => {

        const newOptionsSelected = selectOption(index);

        if(newOptionsSelected === null) return;

        onFoliosSelected && onFoliosSelected(newOptionsSelected);
    };

    const onSelectAll = () => {

        const newOptionsSelected = selectAllOptions();

        onFoliosSelected && onFoliosSelected(newOptionsSelected);
    };

    const filterFolios = () => folios.filter(({ option }) => {

        const folioNum = option.substring(3);

        switch(filterType) {
            case foliosFilterTypes.empieza : return folioNum.startsWith(folio);
            case foliosFilterTypes.termina : return folioNum.endsWith(folio);
            case foliosFilterTypes.incluye : return folioNum.includes(folio);
            default: return true
        }
    });

    const filteredFolios = filterFolios();

    const enableAdd = folio !== "" && filteredFolios.length > 0;
    const enableRemove = filteredOptions.length > 0 || searchFilter !== "";
    const enableButton = !disabled && (enableAdd || enableRemove);

    const { optionsChecked } = getFilteredOptionsInfo();

    const selectedOptionsText = (() => optionsChecked.join(", "))();

    const numberFormatPlaceholder = (() => {

        if(folios.length === 0) return "Sin folios";

        if(filteredOptions.length === 0) return "Todos (" + folios.length + ")";

        if(optionsChecked.length > 0) return selectedOptionsText;

        return "DC -";

    })();

    const foliosMessage = (() => {

        const { length } = filteredFolios;

        if(length > 0) {

            return length < 100 ?  length + " folio(s)" : "+99 folio(s)";
        }
        else return "No existe";

    })();

    return (
        <div
            ref={elementRef}
            className={'folios-select ' + (disabled ? 'disabled' : '')}
            onKeyUp={onKeyUp}
            style={{ ...style }}
            onPointerDown={onPointerDown}
        >
            <select
                className="input input-select"
                style={{ padding: 7 }}
                name="foliosFilterType"
                value={filterType}
                disabled={disabled}
                onChange={(e) => setFilterType(e.target.value)}
            >
                <option value={foliosFilterTypes.empieza}>{foliosFilterTypes.empieza}</option>
                <option value={foliosFilterTypes.termina}>{foliosFilterTypes.termina}</option>
                <option value={foliosFilterTypes.incluye}>{foliosFilterTypes.incluye}</option>
            </select>
            <div
                className="folios-input multi-select"
                onPointerDown={onInputPointerDown}
            >
                <NumberFormat
                    getInputRef={inputRef}
                    value={folio || searchFilter}
                    className={"folios-number-format " + (filteredOptions.length > 0 ? "selected" : "")}
                    displayType={"number"}
                    placeholder={numberFormatPlaceholder}
                    format={"DC - ########"}
                    disabled={disabled}
                    onValueChange={onNumberFormatChange}
                />
                <div style={{ display: "flex", justifyContent: "flex-start", gap: 8 }}>
                    {folio && filteredOptions.length === 0 &&
                        <p>{foliosMessage}</p>
                    }
                    <button
                        type='button'
                        disabled={!enableButton}
                        onPointerDown={(e) => { e.stopPropagation(); e.preventDefault() }}
                        onClick={!enableRemove ? onAddFolios : onRemoveFolios}
                    >
                        {!enableRemove ?
                            <i className="fas fa-plus-square" />
                            :
                            <i className="fas fa-minus-square" />
                        }
                    </button>
                </div>
                <SelectModal
                    ref={modalRef}
                    open={openFoliosSelect && !disabled}
                    options={filteredOptions}
                    optionsHeader={optionsChecked.length + " seleccionado(s)"}
                    allOptionsSelected={allFilteredSelectedOptions}
                    modalLayout={modalLayout}
                    onSelectOption={onSelectOption}
                    onSelectAll={onSelectAll}
                />
            </div>

        </div>
    )
};

const SearchButton = (props) => {

    const { title, iconName, loading, ...restProps } = props;

    return (
        <button
            {...restProps}
            className="btn btn-primary btn-red-table color-white"
        >
            {!loading ?
                <>
                    <i className={iconName} style={{ paddingRight: 4 }}/> {title}
                </>
                :
                <i className="fas fa-spinner fa-spin" />

            }
        </button>
    );
};

const ControlButton = (props) => {

    const { disabled, type, style, ...restProps } = props;

    const iconClassName = type === "search" ? "fas fa-search font-small"
        : type === "filter" ? "fas fa-filter font-small"
            : "fas fa-cog font-small";

    return (
        <button
            {...restProps}
            disabled={false}
            className={'btn btn-primary ' + (disabled ? "btn-disabled" : "")}
            type="button"
            style={{ padding: 10, ...style }}
        >
            <i className={iconClassName} style={{ display: "flex", color: "white", fontSize: 12 }} />
        </button>
    );
};

const ExcelButton = (props) => {

    const { data, loading, disabled, ...restProps } = props;

    return (
        <button
            {...restProps}
            type="button"
            className={"btn btn-primary btn-small color-white " + (disabled ? "btn-disabled" : "")}
            disabled={disabled}
            style={{ display: data ? "flex" : "none", alignItems: "center" }}
        >
            {!loading ?
                <>
                    <i className="far fa-file-excel font-text" style={{ display: "flex"}} />
                    &nbsp; Excel
                </>
                :
                <i className="fas fa-spinner fa-spin" />
            }
        </button>
    );
};


