import React from 'react';
import NumberFormat from 'react-number-format';

export default function CurrencyFormatInput(props) {

    const { value, className, disabled, displayType, onValueChange } = props;

    const currencyFormat = (value) => {
        if (!Number(value)) return '$0.00';

        const amount = new Intl.NumberFormat('es-MX', {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 2
        }).format(parseFloat(value / 100))

        return `${amount}`;
    }

    const isAllowed = ({ formattedValue, floatValue }) => {
        return formattedValue === "" || floatValue / 100 <= 10000000000;
    }

    return (
        <NumberFormat
            value={!value|| !Number(value) ? 0 : Number(value) * 100}
            className={className}
            disabled={disabled}
            displayType={displayType}
            fixedDecimalScale={true}
            decimalScale={2}
            decimalSeparator='.'
            allowNegative={false}
            isAllowed={isAllowed}
            format={currencyFormat}
            onValueChange={onValueChange ? (values) => onValueChange((+values.value / 100).toFixed(2)) : undefined}
        />)
}
