import React, { Component, useState, useEffect } from "react";
import { Consumer } from "../../context";
import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import Controls from "../../components/controls/controls";
import SidebarAdministracion from "../../components/header/sidebarAdministracion";
import cogoToast from "cogo-toast";
import moment, { months } from "moment-timezone";
import DatePicker from "react-datepicker";

import DocsTable from "./components/paymentTable";
import Paginador from "../../components/paginador/paginador";

import SuperCore from "../../components/core/SuperCore";
import SuperModal from "../../components/coreModal/SuperModal";
import NewDoc from "./components/generalDoc";
import CreateDoc from "./components/CreateDoc";

import Request from "../../core/httpClient";
import Importar from "../../components/Billing/Importar";
import Exportar from "../../components/Billing/Exportar";
import Permissions from "../../middlewares/Permissions";
import Eliminar from "../../components/Billing/Eliminar";

import XLSX from "xlsx";

import ReactExport from "react-export-excel";

import sortableData from "../../helpers/sortableDataTable";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const request = new Request();

class Paymentdocuments extends SuperCore {
	constructor(props) {
		super(props);
		this.state = {
			docs: [],
			page: 0,
			loading: true,
			frac_name: null,
			subfraccionamientos: [],
			screens: [],
			screenCreateDocument: [],
			id_menu_activo: null,
			productos: [],
			subfraccionamiento: localStorage.getItem("subfrac") || 0,
			userName: "",
			userId: 0,
			generation: "",
			monthStart: new Date(),
			totalDocs: "",
			confirmDocs: false,
			disabledConfirm: false,

			dataDocs: [],
			loadingTimbrado: false,
			docsTimbrar: [],
			docsSelected: [],
			allSelected: false,
			viviendas: [],

			docsStatus: [],
			docsStatusSelected: [],
			allSelectedStatus: false,

			confirmImport: false,
			sobreEscribeImport: false,
			cargosImportar: [],
			file_name: "",
			closeModal: false,
			total: 0.0,

			eliminarModalToggle: false,

			exportarScreen: [],
			confirmExport: false,
			startDate: new Date(),
			download: false,
			dataExport: [],
			dataImport: {},
			columnas: null,
			escritura: false,
			lectura: false,
			configProductos: [],
			configImpuestos: [],
			lineasNewDoc: [],
			impuestosNewDoc: [],
			idViviendaNew: 0,
			fechaNew: new Date(),
			comentarioNew: "",
			metodosPago: [],
			filter: "",
			pageLength: 20,
			loadingNewDoc: false,
			loadingImport: false,
			loadingDataGeneracion: false,
			loadingGeneracion: false,
			direction: {
				Unidad: "desc",
				Propietario: "desc",
			},
			filterColumn: {
				col: "",
				filt: "",
			},
			submenu: '',
			selectedYear: new Date(),
			filterYear: new Date().getFullYear(),
			selectedStatus: "",
			filterStatus: "",
			isClickedButton: false,

			selectorValue: "Todos",
			isButtonSuccess: false 
		};
		Array.prototype.unique = (function (a) {
			return function () {
				return this.filter(a);
			};
		})(function (a, b, c) {
			return c.indexOf(a, b + 1) < 0;
		});

		this.validarPermisos();

		this.submittedCargosEliminadosCallback = this.submittedCargosEliminados.bind(this);
	}

	async validarPermisos() {
		let permisos = new Permissions();
		let permisosInfo = await permisos.getResult();
		await this.setState({
			escritura: permisosInfo.escritura,
			lectura: permisosInfo.lectura,
		});
	}

	componentDidMount() {
		this.getSubmenu();
		this.userData();
		this.getDataDocuments();
		this.getAccountStatement();
		this.getSubFraccionamientos();
		this.getFraccionamiento();
		this.getMetodosPago();
		this.updateScreens();
		this.updateCreateScreen();
		this.updateImportarScreen();
		this.updateExportarScreen();
		this.loadViviendas();
		this.loadConfigDocument();
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.state.subfraccionamiento !== prevState.subfraccionamiento) {
			this.setState({subfraccionamiento: this.state.subfraccionamiento});
			this.updateScreens();
		}
		if (this.state.userName !== prevState.userName) {
			this.setState({userName: this.state.userName});
			this.updateScreens();
		}
		if (this.state.frac_name !== prevState.frac_name) {
			this.setState({frac_name: this.state.frac_name});
			this.updateScreens();
		}
		if (this.state.totalDocs !== prevState.totalDocs) {
			this.setState({frac_name: this.state.totalDocs});
			this.updateScreens();
		}
		if (this.state.confirmDocs !== prevState.confirmDocs) {
			this.setState({confirmDocs: this.state.confirmDocs});
			this.updateScreens();
		}
		if (this.state.loading !== prevState.loading) {
			this.stickyTablePosition('header', 'controls', 'second_controls', 'docs_table');
		}
	}

	stickyTablePosition(headerId, controlsId, secondControlsId, tableId) {
		const header_Heigh = document.getElementById(headerId);
		const height_controls = document.getElementById(controlsId);
		const second_controls = document.getElementById(secondControlsId);

		const table_height = document.getElementById(tableId);

		let limit_sticy_top = header_Heigh && height_controls ? header_Heigh.offsetHeight + height_controls.offsetHeight : 226;

		if (second_controls) {
			second_controls.style.position = 'sticky';
			second_controls.style.top = `${limit_sticy_top}px`;

			limit_sticy_top += second_controls.offsetHeight;
		};

		if (table_height) {
			table_height.style.position = 'sticky';
			table_height.style.top = `${limit_sticy_top}px`;
		};
	};

	async getSubmenu() {
		const submenu = await this.props.context.getSubmenuAdminName();
		if (submenu) {
			this.setState({ submenu });
		}
	}

	// generales
	async setPage(page) {
		await this.setState({ page });
	}

	async paginatorChange(length) {
		await this.setState({pageLength: length});
		this.setPage(1);
	}

	///

	async exportData(event) {
		event.preventDefault();
		this.getExportConfig();
	}

	async getExportConfig() {
		let mes = this.state.startDate.getMonth() + 1;
		let data = {
			id_fraccionamiento: parseInt(localStorage.getItem("frac")),
			date: `${mes < 10 ? "0" + mes : mes}${this.state.startDate.getFullYear()}`,
		};
		cogoToast.loading("Buscando datos que exportar.", {
			position: "bottom-center",
		})
			.then(async () => {
				const response = await request.post("/documentos/get/config/export", data);
				if (response.empty || response.error) {
					cogoToast.error("No se encontraron datos para exportar.", {
						position: "bottom-center",
						hideAfter: 5,
					});
				}

				if (response.data) {
					await this.updateExportData({ download: true, dataExport: response.data });
					let btn_export = document.getElementById("btn-export");
					if (btn_export) {
						btn_export.click();
						this.superCloseModal("exportar");
						cogoToast.success("El documento se exportará en unos instantes.", {
							position: "bottom-center",
							hideAfter: 30,
						});
					}
				}
			});
	}

	async updateExportarScreen() {
		await this.setState({
			exportarScreen: [
				<Exportar
					startDate={this.state.startDate}
					updateData={this.updateExportData.bind(this)}
					dataSet={this.state.dataExport}
					dataExport={this.state.dataExport}
					frac={this.state.frac_name}
				></Exportar>,
			],
		});
	}

	async updateExportData(data) {
		await this.setState(data);
		this.updateExportarScreen();
	}

	async updateImportarData(data) {
		await this.setState(data);
		this.updateImportarScreen();
	}

	async updateImportarScreen() {
		await this.setState({
			importarScreen: [
				<Importar
					confirmImport={this.state.confirmImport}
					sobreEscribeImport={this.state.sobreEscribeImport}
					cargosImportar={this.state.cargosImportar}
					file_name={this.state.file_name}
					updateData={this.updateImportarData.bind(this)}
					total={this.state.total}
					updateExportData={this.updateExportData.bind(this)}
				></Importar>,
			],
		});
	}

	async triggerCloseModal() {
		await this.setState({ closeModal: !this.state.closeModal });
	}

	async loadConfigDocument() {
		const frac = await localStorage.getItem("frac");
		const data = { id_fraccionamiento: frac };

		const response = await request.get("/documentos/get/config/document", data);
		if (response && !response.error) {
			if (!response.empty) {
				let Productos = JSON.parse(response.Productos);
				let Impuestos = JSON.parse(response.Impuestos);
				await this.setState({
					configProductos: Productos,
					configImpuestos: Impuestos,
				});
			}
		}
	}

	// load

	async getAccountStatement() {
		this.setState({ loading: true });
		let data = {
			id_fraccionamiento: localStorage.getItem("frac"),
			// id_fraccionamiento: 34
		};
		const response = await request.post("/documentos/get/documents", data);
		if (response && !response.error) {
			if (response.documents && !response.empty) {
				let propietarios = [];

				if (response.propietarios && !response.propietarios.empty) {
					propietarios = response.propietarios;
				}

				let docs = response.documents.map((dc) => {
					let prop = propietarios.filter((p) => p.id_vivienda === dc.Id_Vivienda);
					if (prop.length > 0) {
						dc.Propietario = prop[0].nombre;
					}
					return dc;
				});

				this.setState({
					docs: docs,
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
			});
		}
		this.setState({ loading: false });
	}

	async reload() {
		// Recargar los registros principales
		await this.getAccountStatement();
	}

	async getMetodosPago() {
		const result = await request.get("/documentos/get/metodopago/recibo");
		if (!result.empty || !result.error) {
			await this.setState({ metodosPago: result.metodos });
		}
	}

	async loadViviendas() {
		const frac = await localStorage.getItem("frac");
		const dataVivienda = { id_fraccionamiento: frac, id_subfraccionamiento: 0 };
		const responseViviendas = await request.post("/viviendas/getViviendaBySubFraccionamiento", dataVivienda);
		if (responseViviendas && !responseViviendas.error) {
			if (!responseViviendas.empty) {
				await this.setState({ viviendas: responseViviendas.viviendas });
			}
		}
	}

	async userData() {
		let user = await this.getUserInfo();

		this.setState({ userName: user.info.usuario, userId: user.info.id_usuario });
	}

	//

	async clearImportData() {
		this.updateImportarData({
			confirmImport: false,
			sobreEscribeImport: false,
			cargosImportar: [],
			file_name: "",
			closeModal: false,
			total: 0,
		});
	}

	async subChange(val) {
		await this.setState({
			subfraccionamiento: parseInt(val),
		});
		this.updateScreens();
	}

	async setFilter(filter) {
		await this.setState({ filter: filter.toLowerCase() });
	}

	//Metodo para crear un nuevo pago
	async submitCreateDocument() {
		this.setState({ loadingNewDoc: true });

		if (this.state.idViviendaNew === 0) {
			cogoToast.warn("Debes seleccionar una vivienda.", {
				position: "bottom-right",
				hideAfter: 5,
			});

			this.setState({ loadingNewDoc: false });

			return;
		}

		let cuenta = this.state.lineasNewDoc.filter((linea) => linea.status !== 0);
		if (cuenta) {
			if (cuenta.length === 0) {
				cogoToast.warn("El documento debe tener al menos una línea.", {
					position: "bottom-right",
					hideAfter: 5,
				});

				this.setState({ loadingNewDoc: false });

				return;
			} else {
				let productosLineas = cuenta.filter((pl) => pl.concept == "" || pl.concept == 0);

				if (productosLineas.length !== 0) {
					cogoToast.warn("Existe una línea incompleta.", {
						position: "bottom-right",
						hideAfter: 5,
					});

					this.setState({ loadingNewDoc: false });

					return;
				}
			}
		}

		let montoLineas = this.state.lineasNewDoc.filter((ln) => ln.status === 1 && ln.monto === 0);

		if (montoLineas.length !== 0) {
			cogoToast.warn("Existe una línea incompleta.", {
				position: "bottom-right",
				hideAfter: 5,
			});

			this.setState({ loadingNewDoc: false });

			return;
		}

		let lineas = this.state.lineasNewDoc
			.filter((pl) => pl.concept !== "" || (pl.concept !== 0 && pl.status === 1))
			.map((lin) => {
				lin.month = (lin.month.getMonth() + 1 + "").padStart(2, "0") + lin.month.getFullYear();
				return lin;
			});
		//console.log();
		let impuestos = this.state.impuestosNewDoc.filter((impuesto) => impuesto.status !== 0);

		let data = {
			id_vivienda: this.state.idViviendaNew,
			fecha: this.state.fechaNew,
			comentario: this.state.comentarioNew,
			lineas: JSON.stringify(lineas),
			impuestos: JSON.stringify(impuestos),
			userId: this.state.userId,
		};

		const res = await request.post("/documentos/create/document", data);
		lineas = this.state.lineasNewDoc
			.filter((linea) => linea.status !== 0)
			.map((lin) => {
				lin.month = new Date();
				return lin;
			});

		if (res.error) {
			cogoToast.error("No fue posible crear el documento.", {
				position: "bottom-right",
				hideAfter: 5,
			});

			this.setState({ loadingNewDoc: false, lineasNewDoc: lineas });

			return;
		}

		if (res.created) {
			cogoToast.success("Nuevo documento creado.", {
				position: "bottom-right",
				hideAfter: 5,
			});

			this.superCloseModal("createDocument");

			await this.clearNewData();
			await this.updateCreateScreen();
			await this.getAccountStatement();
		} else {
			await this.updateCreateScreen();

			cogoToast.error(res.message || "No fue posible crear el documento.", {
				position: "bottom-right",
				hideAfter: 5,
			});
		}

		this.setState({ loadingNewDoc: false });
	}

	//Metodo para importar pagos
	async import(event) {
		event.preventDefault();

		this.setState({ loadingImport: true });

		const checked = this.state.confirmImport;

		if (!checked) {
			cogoToast.warn("Debe confirmar la generación de los documentos.", {
				position: "bottom-center",
				hideAfter: 5,
			});

			this.setState({ loadingImport: false });

			return;
		}

		cogoToast.info("importando...", {
			position: "bottom-center",
		});

		let user = await this.getUserInfo();

		const data = {
			sobreescribir: this.state.sobreEscribeImport,
			array: this.state.cargosImportar,
			userId: user.info.id_usuario,
			id_fraccionamiento: localStorage.getItem("frac"),
		};

		const response = await request.post("/documentos/import/document", data);

		if (Array.isArray(response.tabla)) {
			cogoToast.success("Datos importados. ", {
				position: "bottom-center",
				hideAfter: 5,
			});
			this.superCloseModal("importar");
			this.reload();
			this.setState({ cargosImportar: response.tabla });
			this.updateImportarScreen();
		} else {
			cogoToast.error(response.message || "Error al importar los documentos.", {
				position: "bottom-center",
				hideAfter: 5,
			});
		}

		this.setState({ loadingImport: false });
	}

	// traer subfraccionamientos
	async getSubFraccionamientos() {
		const fraccionamiento = localStorage.getItem("frac");

		let data = { Idsub: fraccionamiento, filter: 1 };
		const response = await request.post("/admin/administracion/get/subfraccionamientos", data);
		if (response && !response.error) {
			if (response.subfraccionamiento && !response.empty) {
				this.setState({
					subfraccionamientos: response.subfraccionamiento,
				});
				this.updateScreens();
			} else {
				this.setState({
					empty: true,
					message: response.message,
					subfraccionamientos: [],
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
			});
		}
	}

	async getFraccionamiento() {
		const frac = await localStorage.getItem("frac");
		const idFraccionamiento = frac;
		const data = { IdFraccionamiento: idFraccionamiento };

		const response = await request.post("/admin/administracion/get/fraccionamiento", data);

		if (response && !response.error) {
			if (response.fraccionamiento && !response.empty) {
				await this.setState({
					frac_name: response.fraccionamiento[0].nombre,
				});
			}
		}
	}

	async updateData(json) {
		await this.setState(json);
		await this.updateScreens();
	}

	async updateDataNew(json) {
		await this.setState(json);
		await this.updateCreateScreen();
	}

	//  generacion de documentos

	async openModalGeneracion() {
		this.getDataDocuments();
		this.updateScreens();
		this.superOpenModal("newDoc");
	}

	async getDataDocuments() {
		const fraccionamiento = localStorage.getItem("frac");

		await this.setState({ loadingDataGeneracion: true, dataDocs: [] });
		this.updateScreens();

		let data = { id_fraccionamiento: fraccionamiento };
		const response = await request.post("/documentos/get/data/documents", data);
		if (!response.empty && !response.error) {
			let dataRes = Object.values(response.data[0]);
			let date = dataRes[1].split("/");

			let total = response.data.reduce((a, b) => a + b.no_viviendas, 0);

			let apartirDe = new Date(date[1], date[0] - 1, 1);
			apartirDe.setDate(apartirDe.getDate() - 3);
			let today = new Date();

			this.setState({
				dataDocs: response.data,
				generation: dataRes[0],
				monthStart: new Date(date[1], date[0] - 1),
				totalDocs: total,
				confirmDocs: false,
				disabledConfirm: apartirDe > today,
				disableConfirmDate: apartirDe,
			});
		} else {
			this.setState({
				dataDocs: [],
				error: true,
				message: response.message,
			});
		}

		await this.setState({ loadingDataGeneracion: false });
		this.updateScreens();
	}

	async updateScreens() {
		await this.setState({
			screens: [
				<NewDoc
					updateData={this.updateData.bind(this)}
					monthStart={this.state.monthStart}
					generation={this.state.generation}
					fracName={this.state.frac_name}
					subfracs={this.state.subfraccionamientos}
					subfrac={this.state.subfraccionamiento}
					userName={this.state.userName}
					dataDocs={this.state.dataDocs}
					totalDocs={this.state.totalDocs}
					confirmDocs={this.state.confirmDocs}
					loadingDataGeneracion={this.state.loadingDataGeneracion}
					disabledConfirm={this.state.disabledConfirm}
					disableConfirmDate={this.state.disableConfirmDate}
				/>,
			],
		});
	}

	async createGeneracion(event) {

		event.preventDefault();

		await this.setState({ loadingGeneracion: true });

		const frac = await localStorage.getItem("frac");
		const monthNum = this.state.monthStart.getMonth();
		const fullYear = this.state.monthStart.getFullYear();

		if (this.state.totalDocs.length === 0) {
			cogoToast.warn("No existen documentos para crear.", {
				position: "bottom-center",
				hideAfter: 5,
			});

			this.setState({ loadingGeneracion: false });

			return;
		}

		let data = {
			id_fraccionamiento: parseInt(frac),
			id_subfraccionamiento: parseInt(this.state.subfraccionamiento),
			mes: monthNum + 1,
			year: fullYear,
			user: this.state.userId,
		};

		const res = await request.post("/documentos/create/documents", data);

		if (res.created) {
			cogoToast.success("Documentos generados correctamente.", {
				position: "bottom-center",
				hideAfter: 5,
			});
			this.superCloseModal("newDoc");
			this.setState({ dataDocs: [] });
			await this.getAccountStatement();
		} else {
			cogoToast.error(res.message || "No se pudo generar los documentos de cobro.", {
				position: "bottom-center",
				hideAfter: 5,
			});
		}

		this.setState({ loadingGeneracion: false, confirmDocs: false });
	}

	//

	async clearNewData() {
		await this.updateDataNew({
			idViviendaNew: 0,
			lineasNewDoc: [],
			impuestosNewDoc: [],
			comentarioNew: "",
			fechaNew: new Date(),
			nombre_vivienda: "",
		});
	}

	async beforeOpenCreateDocument(event) {
		this.setMenu(0);
		await this.clearNewData();
		this.superOpenModal("createDocument");

		let LineasNewDocumento = [];

		for (let index = 0; index < 6; index++) {
			LineasNewDocumento.push({
				concept: "",
				month: new Date(),
				monto: 0,
				idLinea: Math.ceil(Math.random() * 100) * Math.ceil(Math.random() * 100),
				status: 0,
			});
		}

		await this.setState({
			idViviendaNew: 0,
			lineasNewDoc: LineasNewDocumento,
			impuestosNewDoc: [],
			comentarioNew: "",
			fechaNew: new Date(),
		});

		await this.updateCreateScreen();
	}

	async updateCreateScreen() {
		await this.setState({
			screenCreateDocument: [
				<CreateDoc
					updateDataNew={this.updateDataNew.bind(this)}
					fracName={this.state.frac_name}
					subfrac={this.state.subfraccionamiento}
					userName={this.state.userName}
					viviendas={this.state.viviendas}
					configProductos={this.state.configProductos}
					configImpuestos={this.state.configImpuestos}
					lineasNewDoc={this.state.lineasNewDoc}
					impuestosNewDoc={this.state.impuestosNewDoc}
					idViviendaNew={this.state.idViviendaNew}
					fechaNew={this.state.fechaNew}
					comentarioNew={this.state.comentarioNew}
					nombre_vivienda={this.state.nombre_vivienda}
				/>,
			],
		});
	}

	uploadFile(event) {
		this.setState({ error: false });
		if (event.target.files[0]) {
			const reader = new FileReader();
			const rABS = !!reader.readAsBinaryString;
			if (rABS) {
				reader.readAsBinaryString(event.target.files[0]);
			} else {
				reader.readAsArrayBuffer(event.target.files[0]);
			}
			reader.onload = (e) => {
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, {
					type: rABS ? "binary" : "array",
					bookVBA: true,
				});
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				let data = XLSX.utils.sheet_to_json(ws);
			};
		}
	}

	async selectDoc(id_doc) {
		let docs = this.state.docsSelected;
		let docsFil = [];
		if (parseInt(id_doc) > 0) {
			if (docs.includes(parseInt(id_doc))) {
				docsFil = docs.filter((id) => {
					if (id != parseInt(id_doc)) {
						return parseInt(id_doc);
					}
				});
				docs = docsFil;
			} else {
				docs.push(id_doc);
			}
			await this.setState({docsSelected: docs});
			await this.updateTimbradoScreen();
			return;
		}

		if (parseInt(id_doc) === 0) {
			if (this.state.allSelected) {
				await this.setState({docsSelected: [], allSelected: false});
				await this.updateTimbradoScreen();
				return;
			}

			if (!this.state.allSelected) {
				this.state.docsTimbrar.forEach((doc) => {
					docs.push(doc.Id_DocCobro);
				});
				await this.setState({ docsSelected: docs, allSelected: true });
				await this.updateTimbradoScreen();
				return;
			}
		}
	}

	async selectDocStatus(id_doc) {
		let docs = this.state.docsStatusSelected;
		let docsFil = [];
		if (parseInt(id_doc) > 0) {
			if (docs.includes(parseInt(id_doc))) {
				docsFil = docs.filter((id) => {
					if (id != parseInt(id_doc)) {
						return parseInt(id_doc);
					}
				});
				docs = docsFil;
			} else {
				docs.push(id_doc);
			}
			await this.setState({ docsStatusSelected: docs });
			await this.updateTimbradoScreen();
			return;
		}

		if (parseInt(id_doc) === 0) {
			if (this.state.allSelectedStatus) {
				await this.setState({ docsStatusSelected: [], allSelectedStatus: false });
				await this.updateTimbradoScreen();
				return;
			}

			if (!this.state.allSelectedStatus) {
				this.state.docsStatus.forEach((doc) => {
					docs.push(doc.Id_DocTimbrado);
				});
				await this.setState({
					docsStatusSelected: docs,
					allSelectedStatus: true,
				});
				await this.updateTimbradoScreen();
				return;
			}
		}
	}

	async clearData() {
		await this.setState({
			docsSelected: [],
			allSelected: false,
			docsStatusSelected: [],
			allSelectedStatus: false,
		});
		await this.updateTimbradoScreen();
	}

	async setMenu(id) {
		await this.setState({ id_menu_activo: null });
		await this.setState({ id_menu_activo: id });
	}

	getNumeroRegistro(id_vivienda) {
		let viviendas = this.state.viviendas;
		if (viviendas.length > 0) {
			for (let i = 0; i < viviendas.length; i++) {
				if (parseInt(viviendas[i].id_vivienda) === parseInt(id_vivienda)) {
					return viviendas[i].numero_registro;
				}
			}
		}
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.docs, this.state.direction[key]);

		this.setState({
			docs: items,
			direction: {
				[key]: direction,
			},
			filterColumn: {
				col: key,
				filt: direction,
			},
		});
	}


	async handleSelectedValue(status, year){
		this.setState({ isClickedButton: true });
		await this.setState({ filterStatus: status });
		const selectedYear = year.getFullYear();
		await this.setState({filterYear: selectedYear});
		setTimeout(() => {
			this.setState({ isClickedButton: false });
		}, 1000);
	}

	async filterClean() {
		await this.setState({ 
			filterStatus: "", 
			filterYear: "", 
			selectedStatus: "", 
			isButtonSuccess: true 
		});
		setTimeout(() => {
			this.setState({ isButtonSuccess: false });
		}, 1000); 
	}

	submittedCargosEliminados() {
		this.setState({ eliminarModalToggle: !this.state.eliminarModalToggle });
		this.reload();
		this.superCloseModal("eliminar");
	}

	render() {
		return (
			<div className="admin column">
				<Helmet>
					<title>{process.env.REACT_APP_NAME} - {this.state.submenu}</title>
				</Helmet>
				<Header
					id='header'
					reload={this.reload.bind(this)}
					change={this.reload.bind(this)}
					sidebar={true}
					active={"EstadosCuenta"}
					parent={"EstCuenta"}
					panel={"panel14"}
					nav={"administracion"}
				/>
				<div className="row">
					<div className="column" id="sidebar">
						<SidebarAdministracion />
					</div>
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								<Controls
									id='controls'
									title     = {this.state.submenu}
									nuevo     = {this.beforeOpenCreateDocument.bind(this)}
									dataSet   = {this.state.docs}
									newButton = {this.state.escritura}
									setFilter = {this.setFilter.bind(this)}
									subChange = {this.subChange.bind(this)}
									selectSub = {false}
								/>
								<div className="row">
									<div className="row-responsive full justify-start">
									    <div className="data-to column justify-center" style={{ maxWidth: '130px' }}>
										<button
										className="btn btn-primary btn-small color-white"
										onClick={this.openModalGeneracion.bind(this)}
									>
										<i className="fas fa-file"></i> &nbsp;Generar
									</button>
								        </div>
										&nbsp;
										<div className="data-to column justify-center" style={{ maxWidth: '130px' }}>
										<button
										className="btn btn-primary btn-small color-white"
										onClick={(event) => {
											this.clearImportData();
											this.superOpenModal("importar");
										}}
									>
										<i className="fas fa-file-upload"></i> &nbsp; Importar
									</button>
								        </div>
										&nbsp;
										<div className="data-to column justify-center" style={{ maxWidth: '130px' }}>
										<button
										className="btn btn-primary btn-small color-white"
										onClick={(event) => {
											this.superOpenModal("exportar");
										}}
									>
										<i className="fas fa-file-excel"></i> &nbsp; Exportar
									</button>
								        </div>
										&nbsp;
										<div className="data-to column justify-center" style={{ maxWidth: '130px' }}>
										<button
										className="btn btn-primary btn-small color-white"
										onClick={(event) => {
											this.superOpenModal("eliminar");
										}}
									>
										<i className="fas fa-trash"></i> &nbsp; Eliminar
									</button>
								        </div>
									</div>
								    <div id='second_control' className="row-responsive full justify-end" style={{ backgroundColor: 'white' }} >
									    <div className="row">
									        <div className="data-from column justify-center">
											    <h5 className="color-black">Estado:</h5>
										    </div>
										    <div className="column">
											<select 
											    className="input input-select" 
												onChange={(event) => this.setState({ selectedStatus: event.target.value })}
												value={this.state.selectedStatus || ""} 
												>
											        <option value="">Todos</option>
													<option value="Cobrado">Cobrado</option>
													<option value="Pagado">Pagado</option>
													<option value="Parcial">Parcial</option>
													<option value="Pendiente">Pendiente</option>																							
												</select>
											</div>
									    </div>
										<div style={{ width: '16px' }}></div>
										<div className="row">
										    <div className="data-to column justify-center">
											    <h5 className="color-black">Año:</h5>
										    </div>
										    <div className="column full">
								            <DatePicker
											    className="input input-select"
                                                selected={this.state.selectedYear}
                                                onChange={(date) => { console.log("Selected year: ", date); this.setState({ selectedYear: date }) }}                                               
                                                dateFormat="yyyy"
												showYearPicker
                                            />
										    </div>
										</div>
										<div style={{ width: '16px' }}></div>
										<div className="data-to column justify-center" style={{ maxWidth: '80px' }}>
											<button 
											    type="button" 
												className={`btn btn-primary btn-small color-white justify-center ${this.state.isClickedButton ? 'btn-success' : ''}`}
												onClick={this.handleSelectedValue.bind(this, this.state.selectedStatus, this.state.selectedYear)}
											>
												{this.state.isClickedButton ? <i className="fas fa-check-circle" style={{margin: '6px'}}></i> : 'Buscar'}
											</button>
										</div>
										<div style={{ width: '8px' }}></div>
										<div className="data-to column justify-center" style={{ maxWidth: '40px' }}>
											<button 
												type="button" 
												className={`btn btn-small color-white ${this.state.isButtonSuccess ? 'btn-success' : 'btn-primary'}`}
												style={{padding: "2px"}}
												onClick={this.filterClean.bind(this)}
											>
												<i className="fas fa-broom"/>
											</button>
										</div>
								    </div>
								</div>
								<div className="white-space-16"></div>
								{this.state.loading ? (
									<div className="row justify-center" >
										<div className="white-space-16"></div>
										<i className="fas fa-spinner fa-spin"></i>
										<div className="white-space-16"></div>
									</div>
								) : (
									<DocsTable
										id='docs_table'
										docs={this.state.docs}
										page={this.state.page}
										reload={this.getAccountStatement.bind(this)}
										escritura={this.state.escritura}
										fracName={this.state.frac_name}
										subfraccionamientos={this.state.subfraccionamientos}
										userName={this.state.userName}
										userId={this.state.userId}
										metodosPago={this.state.metodosPago}
										getDataDocuments={this.getAccountStatement.bind(this)}
										filter={this.state.filter}
										pageLength={this.state.pageLength}
										sortBy={this.sortableTable.bind(this)}
										filterColumn={this.state.filterColumn}
										filterStatus={this.state.filterStatus}
										filterYear={this.state.filterYear}
									/>
								)}
								<div className="white-space-16"></div>
								<Paginador
									pages={Math.ceil(this.state.docs.length / this.state.pageLength)}
									setPage={this.setPage.bind(this)}
									pageLength={this.state.pageLength}
									paginatorChange={this.paginatorChange.bind(this)}
									showPageLenght={true}
								/>
							</div>
						</div>
					</div>
				</div>

				<SuperModal
					id={"newDoc"}
					title={"Generación de documentos de cobro"}
					screens={this.state.screens}
					size={"small"}
					lblSubmit={"Generar"}
					showSubmit={this.state.confirmDocs}
					onSubmit={this.createGeneracion.bind(this)}
					loading={this.state.loadingGeneracion}
				/>

				<SuperModal
					id={"importar"}
					title={"Importación de documentos de cobro"}
					screens={this.state.importarScreen}
					onSubmit={this.import.bind(this)}
					lblSubmit={"Aceptar"}
					lblCancel={"Cerrar"}
					showSubmit={this.state.confirmImport}
					triggerCloseModal={this.triggerCloseModal.bind(this)}
					loading={this.state.loadingImport}
				/>

				<EliminarModal 
					viviendas={this.state.viviendas}
					getUserInfo={this.getUserInfo}
					modalToggle={this.state.eliminarModalToggle}
					onSuccessfulSubmit={this.submittedCargosEliminadosCallback}
				/>
				<SuperModal
					id={"exportar"}
					title={"Exportar documentos de cobro"}
					size={"small"}
					screens={this.state.exportarScreen}
					onSubmit={this.exportData.bind(this)}
					lblSubmit={"Aceptar"}
				/>

				<SuperModal
					id={"createDocument"}
					size={"big"}
					title={"Nuevo documento de cobro"}
					screens={this.state.screenCreateDocument}
					menus_no={["Documento", "Reglas"]}
					onSubmit={this.submitCreateDocument.bind(this)}
					lblSubmit={"Guardar"}
					showSubmit={true}
					triggerCloseModal={this.clearNewData.bind(this)}
					loading={this.state.loadingNewDoc}
				/>
			</div>
		);
	}
}

export default Consumer(Paymentdocuments);

const EliminarModal = (props) => {

	const { viviendas, modalToggle, getUserInfo, onSuccessfulSubmit } = props;

	const [cargosEliminar, setCargosEliminar] = useState([]);
	const [confirmEliminar, setConfirmEliminar] = useState(false);
	const [loadingEliminar, setloadingEliminar] = useState(false);

	useEffect(() => {
	  
		setCargosEliminar([]);
		setConfirmEliminar(false);

	}, [modalToggle]);
	
	const screen = [
		<Eliminar
			key={modalToggle}
			confirmEliminar={confirmEliminar}
			cargosEliminar={cargosEliminar}
			viviendas={viviendas}
			cargos={cargosEliminar}
			onCargosChanged={(cargos) => setCargosEliminar(cargos)}
			onConfirmEliminar={(confirm) => setConfirmEliminar(confirm)}
		/>
	];

	const submitEliminar = async (event) => {
		event.preventDefault();

		setloadingEliminar(true);

		let user = await getUserInfo();

		const data = {
			array: cargosEliminar,
			userId: user.info.id_usuario,
		};

		const response = await request.post("/documentos/eliminar", data);

		if (Array.isArray(response.tabla)) {
			cogoToast.success("Datos procesados.", {
				position: "bottom-center",
				hideAfter: 5,
			});

			onSuccessfulSubmit();

		} else {
			cogoToast.error(response.message || "Error al eliminar los documentos.", {
				position: "bottom-center",
				hideAfter: 5,
			});
		}

		setloadingEliminar(false);
	}

	return (
		<SuperModal
			id={"eliminar"}
			title={"Eliminación de documentos de cobro"}
			screens={screen}
			onSubmit={submitEliminar}
			lblSubmit={"Aceptar"}
			lblCancel={"Cerrar"}
			showSubmit={confirmEliminar}
			loading={loadingEliminar}
		/>
	)
}
