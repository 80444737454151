// React and third-parties
import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';

export default function FechaPagosModal(props) {

    const { id, open, style, itemHeight = 25, maxScrollItems = 6, children } = props;

    const modalRef = useRef();

    const [modalSettings, setModalSettings] = useState({ heightOffset: 0, widthOffset: 0 });
    const [scrollPosition, setScrollPosition] = useState('scroll-top');

    const sizeObserverHandler = (entries) => {
        if (entries[0]) {
            const { blockSize: heightOffset, inlineSize } = entries[0].borderBoxSize[0];
            const widthOffset = inlineSize / 2;
            setModalSettings({ heightOffset, widthOffset });
        }
    };

    useEffect(() => {

        const observer = new ResizeObserver(sizeObserverHandler);
        observer.observe(modalRef.current, { box: "border-box" });

        return () => observer.disconnect();

    }, []);

    useEffect(() => {

        const observer = new MutationObserver((mutations) => 
            manageTableBounds(mutations, modalRef, maxScrollItems, itemHeight));

        observer.observe(modalRef.current, { childList: true, subtree: true });

        return () => observer.disconnect();

    }, [maxScrollItems, itemHeight]);
    
    const onScroll = (e) => {

        const { scrollTop, scrollHeight, clientHeight } = e.target;

        const scrollPosition = scrollTop < 12 ? "scroll-top"
             : scrollTop + clientHeight >= scrollHeight - 12 ? "scroll-bottom" 
             : "scroll-middle";

        setScrollPosition(scrollPosition);
    };
    
    const { top, left, ...restStyle } = style;

    const modal = <div
        ref={modalRef}
        id={id}
        className={'fecha-pagos-modal column ' + scrollPosition}
        style={{
            opacity: open ? 1 : 0,
            pointerEvents: open ? 'all' : 'none',
            top: (style.top || 0) - modalSettings.heightOffset - 2,
            left: (style.left || 0) - modalSettings.widthOffset,
            ...restStyle
        }}
    >
        <div key={children} className={'fecha-pagos-modal-anchor'}>
            <div onScroll={onScroll} className='fecha-pagos-modal-scroll'>
                {children}
            </div>
        </div>
    </div>;

    return createPortal(modal, document.body);
};

const manageTableBounds = (mutations, modalRef, maxScrollItems, itemHeight) => {
    
    const getScrollableInfo = (table) => {

        const theadHeight = table.querySelector('thead').clientHeight
        const maxHeight = Math.floor((maxScrollItems * itemHeight) + theadHeight);
        const isScrollable = table.clientHeight >= maxHeight;

        return { theadHeight, maxHeight, isScrollable };
    };

    const addBounds = (theadHeight) => {

        const divUpperNode = document.createElement('div');
        const divIUpperNode = document.createElement('i');

        divIUpperNode.className = 'fas fa-chevron-up upper-bound-icon';

        divUpperNode.appendChild(divIUpperNode);

        const divBottomNode = divUpperNode.cloneNode(true);
        divBottomNode.querySelector('i').setAttribute('class', 'fas fa-chevron-down bottom-bound-icon');

        divUpperNode.className = 'fecha-pagos-modal-scroll-bound upper';
        divBottomNode.className = 'fecha-pagos-modal-scroll-bound bottom';

        divUpperNode.style.top = theadHeight + 'px';
        divBottomNode.style.bottom = '0px';

        const divAppend = modalRef.current.querySelector('.fecha-pagos-modal-scroll');
        divAppend.appendChild(divUpperNode);
        divAppend.appendChild(divBottomNode);
    };

    const removeBounds = () => {

        const divsAppended = modalRef.current.querySelectorAll('.fecha-pagos-modal-scroll-bound');

        const divRemove = modalRef.current.querySelector('.fecha-pagos-modal-scroll');

        divRemove.removeChild(divsAppended[0]);
        divRemove.removeChild(divsAppended[1]);
    };

    const hasBounds = modalRef.current.querySelector('.fecha-pagos-modal-scroll-bound') !== null;
    const tableAdded = mutations.some(({ addedNodes }) => Array.from(addedNodes).some(({ nodeName }) => nodeName === 'TABLE'));

    const table = modalRef.current.querySelector('table');
    
    if (!hasBounds && tableAdded) {

        const { isScrollable, maxHeight, theadHeight} = getScrollableInfo(table);

        if(isScrollable) {

            modalRef.current.querySelector('.fecha-pagos-modal-scroll').style.setProperty('--max-height', maxHeight + "px");

            addBounds(theadHeight);
        }
    }

    if(hasBounds) {

        if(table === null) { removeBounds(); return; }

        const { isScrollable } = getScrollableInfo(table);

        if(!isScrollable) removeBounds();
    }
};
