import moment from "moment-timezone";
import XLSX from "xlsx";

const isFloat = (n) => Number(n) === n && n % 1 !== 0;
const isInteger = (n) => Number(n) === n && n % 1 === 0;

const cellMaxLength = 32767;
// Busca strings del tamaño maximo 'cellMaxLength'
const excelExp = new RegExp(`.{1,${cellMaxLength}}`, 'g');

const useExcel = () => {

    const setDefaultExportFormat = (dataSet, title, fraccionamiento, usuario, dateFormat, resTable,
        { excelColumnsShow, excelColumnsName, excelColumnsType }) => {

        // Definición de columnas
        const columnParameters = excelColumnsShow.split(",");
        const columnNames = excelColumnsName.split(",");
        const columnTypes = excelColumnsType.split(",");

        // Construir las filas
        let rows = [];
        dataSet.forEach((element) => {
            const object = {};
            columnParameters.forEach((columnShow, index) => {
                const column = columnNames[index];
                const row = element[columnShow];
                object[column] = row;
            });

            if (element.tipo === "Restaurantes") object["id_restaurante"] = element.id_restaurante;
            rows.push(object);
        });

        return {
            columns: columnNames,
            rows,
            excelColumnsType: columnTypes,
            titulo: title,
            fraccionamiento: fraccionamiento || "Appmosphera",
            usuario: usuario || "",
            fecha: moment().format("DD/MM/YYYY HH:mm"),
            dateFormat,
            tz: moment.tz.guess(),
            restataurantes_table: resTable ? true : undefined
        };
    }

    const setConcentradoExportFormat = (dataSet, title, fraccionamiento, usuario, dateFormat, resTable,
        { excelColumnsShow, excelColumnsName, excelSubColumnsName, 
            excelSubColumnsType, excelColumnsType, excelSubColumnsShow, enabledHeadersIndeces }) => {

        // Definición de columnas
        const columnParameters = excelColumnsShow.split(",");
        const columnNames = excelColumnsName.split(",");
        const subColumnNames = excelSubColumnsName.split(",");
        const columnTypes = excelColumnsType.split(",");
        const subColumnTypes = excelSubColumnsType.split(",");
        const subColumnShow = excelSubColumnsShow.split(",");

        // Construir las filas
        let rows = [];
        const firstParameters = columnParameters.slice(0, -1);
        const lastParameter = columnParameters.slice(-1);

        dataSet.forEach((element) => {
            const object = {};

            firstParameters.forEach((columnShow, index) => {
                const column = columnNames[index];
                const row = element[columnShow];
                object[column] = row;
            });

            columnNames.slice(firstParameters.length).forEach((name, index) => {
                const enableIndex = enabledHeadersIndeces[index];
                const row = element[lastParameter][enableIndex];
                object[name] = row;
            });

            rows.push(object);
        });

        return {
            columns: columnNames,
            rows,
            regularColumnsCount: firstParameters.length,
            subColumns: subColumnNames,
            excelColumnsType: columnTypes,
            excelSubColumnsType: subColumnTypes,
            excelSubColumnsShow: subColumnShow,
            titulo: title,
            fraccionamiento: fraccionamiento || "Appmosphera",
            usuario: usuario || "",
            fecha: moment().format("DD/MM/YYYY HH:mm"),
            dateFormat,
            tz: moment.tz.guess(),
        };
    }

    const generateExcel = (data) => {
        try {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet([], { dense: true });

            const createdAtString = data.fecha;
            const createdAt = moment(createdAtString, "DD/MM/YYYY HH:mm").toDate();
            XLSX.utils.sheet_add_aoa(ws, [[data.fraccionamiento]], { origin: "A1" });
            XLSX.utils.sheet_add_aoa(ws, [[data.titulo]], { origin: "A2" });
            XLSX.utils.sheet_add_aoa(ws, [[createdAt]], { origin: "A3", dateNF: "dd/mm/yyyy hh:mm" });
            //ws["!data"][2][0].z = "dd/mm/yyyy hh:mm";

            const startRow = 4;

            data.columns.forEach((column, columnIndex) => {
                const cellAddress = XLSX.utils.encode_cell({
                    r: startRow,
                    c: columnIndex,
                });
                XLSX.utils.sheet_add_aoa(ws, [[column]], { origin: cellAddress });
                ws["!cols"] = ws["!cols"] || [];
                ws["!cols"][columnIndex] = { wch: 20 };
            });

            data.rows.forEach((row, rowIndex) => {
                let dateTimeCols = [];
                const rowData = data.columns.map((column, columnIndex) => {

                    const dataType = data.excelColumnsType && data.excelColumnsType[columnIndex];
                    return setCellValueAndFormat(dataType, row[column], columnIndex, dateTimeCols);
                });

                const rowIndexWithOffset = startRow + rowIndex + 1;
                XLSX.utils.sheet_add_aoa(ws, [rowData], {
                    origin: { r: rowIndexWithOffset, c: 0 },
                });
                dateTimeCols.forEach((col) => {
                    ws["!data"][rowIndexWithOffset][col].z = "dd/mm/yyyy hh:mm";
                });
            });

            if (data.restataurantes_table) {

                const restaurantes = data.rows.reduce((prev, current) => {

                    if (current.Tipo === "Restaurantes") {

                        if (Object.keys(prev).find(key => key === current.id_restaurante)) {
                            return prev;
                        }

                        prev[current.id_restaurante] = { nombre: current.Detalle, cell: { t: "n", z: "$#,##0.00", f: "" } };
                    }

                    return prev;

                }, {});

                if (Object.entries(restaurantes).length > 0) {

                    const startColumn = data.columns.length + 1;
                    const catalogoColumnIndex = data.columns.findIndex(column => column === "Tipo");
                    const cantidadColumnIndex = data.columns.findIndex(column => column === "Cantidad");

                    data.rows.forEach((row, rowIndex) => {
                        data.columns.forEach((column, columnIndex) => {
                            if (columnIndex === catalogoColumnIndex && row[column] === "Restaurantes") {
                                const key = row.id_restaurante;
                                restaurantes[key].cell.f += XLSX.utils.encode_cell({ r: startRow + 1 + rowIndex, c: cantidadColumnIndex }) + "+";//String.fromCharCode(initColumnKey) + (startRow + 2 + rowIndex) + "+";
                            }
                        })
                    })

                    const startTableCell = XLSX.utils.encode_cell(({ r: startRow, c: startColumn }));
                    const startSumCell = XLSX.utils.encode_cell(({ r: startRow + 1, c: startColumn + 1 }));
                    const endSumCell = XLSX.utils.encode_cell(({ r: startRow + 1 + Object.keys(restaurantes).length - 1, c: startColumn + 1 }));

                    const totalSumRange = `SUM(${startSumCell}:${endSumCell})`;

                    Object.values(restaurantes).forEach((restaurante) => {
                        if (restaurante.cell) {
                            const formulaString = restaurante.cell.f;
                            restaurante.cell.f = formulaString.slice(0, formulaString.length - 1);
                        }
                    });

                    const restaurantesTable = [
                        ["Restaurantes", "Cantidad"],
                        ...Object.values(restaurantes).map(restaurante => [restaurante.nombre, restaurante.cell]),
                        ["Total", { t: "n", z: "$#,##0.00", f: totalSumRange }]
                    ]

                    ws["!cols"][startColumn] = { wch: 20 };
                    ws["!cols"][startColumn + 1] = { wch: 20 };

                    XLSX.utils.sheet_add_aoa(ws, restaurantesTable, { origin: startTableCell });
                }
            }

            XLSX.utils.book_append_sheet(wb, ws, "Appmosphera");
            const excelData = XLSX.write(wb, { type: "array", bookType: "xlsx" });
            return { buffer: excelData };
        } catch (error) {
            console.log(error);
            return { error: true, message: error.message };
        }
    };

    return {
        setDefaultExportFormat,
        setConcentradoExportFormat,
        generateExcel,
        generateDetalladosExcel,
        generateConcentradosExcel
    };
};

export default useExcel;

const generateDetalladosExcel = (data, filters) => {

    try {
        const { columns, rows, excelColumnsType } = data;

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([], { dense: true });
        const { sheet_add_aoa, encode_cell, encode_range } = XLSX.utils;

        const createdAtString = data.fecha;
        const createdAt = moment(createdAtString, "DD/MM/YYYY HH:mm").toDate();
        sheet_add_aoa(ws, [[data.fraccionamiento]], { origin: "A1" });
        sheet_add_aoa(ws, [[data.titulo]], { origin: "A2" });
        sheet_add_aoa(ws, [[createdAt]], { origin: "A3", dateNF: "dd/mm/yyyy hh:mm" });

        const filterEntries = Object.entries(filters);
        const titleOffset = 4;

        filterEntries.forEach(([key, value], index) => {
            const cellAddress = encode_cell({ r: titleOffset + index, c: 0, });

            const cellValue = value.length >= cellMaxLength ?
                value.match(excelExp) : [value];

            sheet_add_aoa(ws, [[key, ...cellValue, ' ']], { origin: cellAddress });
        });

        const startRow = titleOffset + filterEntries.length + 1;

        const autofilter = encode_range({ c: 0, r: startRow }, 
            { c: columns.length - 1, r: startRow + rows.length 
        });

        ws["!autofilter"] = { ref: autofilter };

        columns.forEach((column, columnIndex) => {
            const cellAddress = encode_cell({
                r: startRow,
                c: columnIndex,
            });
            sheet_add_aoa(ws, [[column]], { origin: cellAddress });
            ws["!cols"] = ws["!cols"] || [];
            ws["!cols"][columnIndex] = { wch: 20 };
        });

        rows.forEach((row, rowIndex) => {
            let dateTimeCols = [];
            const rowData = columns.map((column, columnIndex) => {

                const dataType = excelColumnsType && excelColumnsType[columnIndex];
                return setCellValueAndFormat(dataType, row[column], columnIndex, dateTimeCols);
            });

            const rowIndexWithOffset = startRow + rowIndex + 1;
            sheet_add_aoa(ws, [rowData], {
                origin: { r: rowIndexWithOffset, c: 0 },
            });
            dateTimeCols.forEach((col) => {
                ws["!data"][rowIndexWithOffset][col].z = "dd/mm/yyyy hh:mm";
            });

        });

        const lastRow = startRow + rows.length + 1;

        const startTableCell = encode_cell(({ r: lastRow, c: 0 }));

        const totalesSum = columns.slice(4).map((column, columnIndex) => {

            const startSumCell = encode_cell(({ r: startRow + 1, c: columnIndex + 4 }));
            const endSumCell = encode_cell(({ r: lastRow - 1, c: columnIndex + 4 }));

            const totalSumRange = `SUM(${startSumCell}:${endSumCell})`;

            return { t: "n", z: "$#,##0.00", f: totalSumRange }
        });

        const totalesRow = [['Totales', '', '', '', ...totalesSum]];
        sheet_add_aoa(ws, totalesRow, { origin: startTableCell });

        XLSX.utils.book_append_sheet(wb, ws, "Appmosphera");
        const excelData = XLSX.write(wb, { type: "array", bookType: "xlsx" });
        return { buffer: excelData };

    } catch (error) {
        console.log(error);
        return { error: true, message: error.message };
    }
};

const generateConcentradosExcel = (data, filters) => {

    try {
        const { columns, rows, subColumns, regularColumnsCount, excelColumnsType, excelSubColumnsType, excelSubColumnsShow } = data;

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([], { dense: true });
        const { sheet_add_aoa, encode_cell, encode_range } = XLSX.utils;

        const createdAtString = data.fecha;
        const createdAt = moment(createdAtString, "DD/MM/YYYY HH:mm").toDate();
        sheet_add_aoa(ws, [[data.fraccionamiento]], { origin: "A1" });
        sheet_add_aoa(ws, [[data.titulo]], { origin: "A2" });
        sheet_add_aoa(ws, [[createdAt]], { origin: "A3", dateNF: "dd/mm/yyyy hh:mm" });

        const filterEntries = Object.entries(filters);
        const titleOffset = 4;

        filterEntries.forEach(([key, value], index) => {
            const cellAddress = encode_cell({ r: titleOffset + index, c: 0, });
            sheet_add_aoa(ws, [[key, value, ' ']], { origin: cellAddress });
        });

        const startRow = titleOffset + filterEntries.length + 1; 

        const subColumnsLength = subColumns.length;
        ws["!cols"] = ws["!cols"] || [];
        ws["!merges"] = ws["!merges"] || [];

        const regularColumns = columns.slice(0, regularColumnsCount);
        const subColColumns = columns.slice(regularColumnsCount);

        regularColumns.forEach((column, index) => {
            const cellAddress = encode_cell({ r: startRow, c: index });
            const subColAddress = encode_cell({ r: startRow + 1, c: index });
            sheet_add_aoa(ws, [[column]], { origin: cellAddress });
            sheet_add_aoa(ws, [[""]], { origin: subColAddress });
            ws["!cols"][0] = { wch: 20 };
        });

        subColColumns.concat("Total").forEach((column, index) => {
            const colOffset = regularColumnsCount + (subColumnsLength * index)
            const cellAddress = encode_cell({ r: startRow, c: colOffset });
            sheet_add_aoa(ws, [[column]], { origin: cellAddress });
            ws["!merges"][index] = { 
                s: { r: startRow, c: colOffset}, 
                e: { r: startRow, c: colOffset + subColumnsLength - 1}
            };

            subColumns.forEach((subCol, subColIndex) => {
                const subColOffset = colOffset + subColIndex;
                const subColAddress = encode_cell({ r: startRow + 1, c: subColOffset });
                sheet_add_aoa(ws, [[subCol]], { origin: subColAddress });
                ws["!cols"][subColOffset] = { wch: 20, };
            });
        });

        rows.forEach((row, rowIndex) => {
            let dateTimeCols = [];
            const rowIndexWithOffset = startRow + rowIndex + 2;

            const initRegularColumns = regularColumns.map((column, index) => {
                const dataType = excelColumnsType && excelColumnsType[index];
                return setCellValueAndFormat(dataType, row[column], index, dateTimeCols)
            });

            const { preTotalColumns, subColsSumCells } = subColColumns.reduce((rowsInfo, column, index) => {

                const subCols = excelSubColumnsShow.map((key, subColIndex) => {
                    const dataType = excelSubColumnsType && excelSubColumnsType[subColIndex];
                    const value = row[column][key];

                    const subColOffset = regularColumnsCount + (subColumnsLength * index) + subColIndex;
                    const sumCell = encode_cell(({ r: rowIndexWithOffset, c: subColOffset }));

                    rowsInfo.subColsSumCells[subColIndex].push(sumCell);

                    return setCellValueAndFormat(dataType, value, subColIndex + regularColumnsCount, dateTimeCols);
                });

                rowsInfo.preTotalColumns = rowsInfo.preTotalColumns.concat(subCols);

                return rowsInfo;

            }, { 
                preTotalColumns: initRegularColumns, 
                subColsSumCells: Array.from({ length: subColumnsLength }).map(() => [])
            });

            const rowsWithTotales = subColsSumCells.reduce((cols, sumArray, index) => {

                const totalSumCollection = `SUM(${sumArray.join(",")})`;
                cols.push({ t: "n", z: "$#,##0.00", f: totalSumCollection });
                return cols;

            }, preTotalColumns);

            sheet_add_aoa(ws, [rowsWithTotales], { origin: { r: rowIndexWithOffset, c: 0 } });
            dateTimeCols.forEach((col) => {
                ws["!data"][rowIndexWithOffset][col].z = "dd/mm/yyyy hh:mm";
            });
        });

        const lastRow = startRow + rows.length + 2;

        const startTotalesRow = encode_cell(({ r: lastRow, c: 0 }));
        const startCobranzaRow = encode_cell(({ r: lastRow + 1, c: 0 }));

        const totalesSumRow = subColColumns.concat('Totales').reduce((columns, column, index) => {

            const subCols = excelSubColumnsShow.map((key, subColIndex) => {

                const subColOffset = regularColumnsCount + (subColumnsLength * index) + subColIndex;
                const startSumCell = encode_cell(({ r: startRow + 2, c: subColOffset }));
                const endSumCell = encode_cell(({ r: lastRow - 1, c: subColOffset }));

                const totalSumRange = `SUM(${startSumCell}:${endSumCell})`;
                return { t: "n", z: "$#,##0.00", f: totalSumRange };
            });

            columns = columns.concat(subCols);

            return columns;

        }, ['Totales', ...Array(regularColumnsCount - 1).fill('')]);

        const cobranzaRow = subColColumns.concat("Totales").reduce((columns, column, index) => {

            const colOffset = regularColumnsCount + (subColumnsLength * index);
            const encodeRecurring = (index) => encode_cell({ r: lastRow, c: colOffset + index })

            const numerator = encode_cell({ r: lastRow, c: colOffset + subColumnsLength - 1 });

            const denominator = subColumnsLength === 5 ?
                    `(${encodeRecurring(0)}-${encodeRecurring(1)}-${encodeRecurring(2)}+${encodeRecurring(3)})`
                : `${encodeRecurring(0)}`;

            const format = { t: "n", z: "##0.0%", f: numerator + "/" + denominator };

            columns = columns.concat([...Array(subColumnsLength - 1).fill(''), format]);

            return columns;

        }, ['Cobranza', ...Array(regularColumnsCount - 1).fill('')]);

        sheet_add_aoa(ws, [totalesSumRow], { origin: startTotalesRow });
        sheet_add_aoa(ws, [cobranzaRow], { origin: startCobranzaRow });

        XLSX.utils.book_append_sheet(wb, ws, "Appmosphera");
        const excelData = XLSX.write(wb, { type: "array", bookType: "xlsx" });
        return { buffer: excelData };

    } catch (error) {
        console.log(error);
        return { error: true, message: error.message };
    }
};

const setCellValueAndFormat = (dataType, value, index, dateTimeCols) => {

    if (dataType === "number" || dataType === "money") {
        const cellValue =
            isFloat(value) || isInteger(value)
                ? Number(value)
                : 0;
        const cellFormat = dataType === "money" ? "$#,##0.00" : "General";
        return { v: cellValue, t: "n", z: cellFormat };
    } else if (dataType === "date") {
        return moment(value).isValid()
            ? moment(value).toDate()
            : "";
    } else if (dataType === "datetime") {
        dateTimeCols.push(index);
        if (moment(value).isValid()) {
            return new Date(value);
        } else {
            return "";
        }
    } else if (dataType === "image") {
        return "No disponible";
    } else {
        return value;
    }
};
