import React, { useState, useEffect } from "react";
import { registerLocale } from "react-datepicker";
import NumberFormat from "react-number-format";
import es from "date-fns/locale/es";
import moment from "moment";

registerLocale("es", es);

function NewReceipment(props) {

	const [viviendas, setViviendas] = useState(props.viviendas);
	const [metodosPago, setMetodosPago] = useState(props.metodosPago);
	const [linesReceipment, setLinesReceipment] = useState([]);
	const [aplicado, setAplicado] = useState(props.aplicado);
	const [loadingDetalle, setLoadingDetalle] = useState(props.loadingDetalle);
	const [lineaSelected, setLineaSelected] = useState({});

	useEffect(() => {
		setViviendas(props.viviendas);
		setMetodosPago(props.metodosPago);
		setLinesReceipment(props.linesReceipment);
		setAplicado(props.aplicado);
		setLoadingDetalle(props.loadingDetalle);
	}, [props.viviendas, props.metodosPago, props.linesReceipment, props.aplicado, props.loadingDetalle]);

	const addLines = () => {

		let lista = linesReceipment;

		let idt = Math.ceil(Math.random() * 92) * Math.ceil(Math.random() * 69);

		let line = {
			Id_Forma_Cobro: idt,
			Fecha_Pago: moment().format("YYYY-MM-DD"),
			Monto: 0,
			Id_FormaPago: 0,
			BANCO: "",
			NO_MOVIMIENTO: "",
			Id_Vivienda: 0,
			Edit: false,
			Text: "",
		};

		lista.push(line);

		setLinesReceipment(lista);

		props.updateData({ linesReceipment: lista });

		const toFocus = document.getElementById(`ln${line.Id_Forma_Cobro}`);

		if (toFocus) {
			toFocus.focus();
		};

	};

	function openRow(linea) {

		if (linea.Id_Forma_Cobro === lineaSelected.Id_Forma_Cobro) {
			setLineaSelected({});
		} else {
			setLineaSelected(linea);
		};
	};

	function isOk(line) {

		let { Monto, Id_FormaPago, Id_Vivienda, Text } = line;

		return (Monto === 0 && Id_FormaPago === 0 && Id_Vivienda === 0 && Text === "") || (Monto > 0 && Id_FormaPago > 0 && Id_Vivienda !== 0);
	};

	function addEventESC(event) {

		if (event.keyCode === 27) {
			event.stopPropagation();
			event.preventDefault();
			return false;
		};

		event.preventDefault();
	};

	function newTable() {
		return (
			<React.Fragment>
				<div className="table-history full" id="table-problem">
					<table className=" full ">
						<thead>
							<tr>
								<th className="th-sticky text-center col-unidad border-left">Vivienda</th>
								<th className="th-sticky text-center">Fecha</th>
								<th className="th-sticky text-center">Importe</th>
								<th className="th-sticky text-center col-metodo">Formas de pago</th>
								<th className="th-sticky text-center">Banco</th>
								<th className="th-sticky text-center border-right">No. Movimiento</th>
							</tr>
						</thead>
						<tbody>
							{loadingDetalle ? (
								<tr>
									<td className="text-center" colSpan={"6"}>
										<div className="white-space-8"></div>
										<div className="row justify-center">
											<i className="fas fa-spinner fa-spin"></i>
										</div>
										<div className="white-space-8"></div>
									</td>
								</tr>
							) : (
								linesReceipment.map((line, key) => {
									return (
										<React.Fragment key={key} >
											<tr className={`${isOk(line) ? "" : "not-ok"}`}>
												<td className={`text-center border-left`}>
													<React.Fragment>
														<input
															type="text"
															id={`ln${line.Id_Forma_Cobro}`}
															className={`input input-modals `}
															disabled={aplicado}
															autoComplete="off"
															name="unidad-list"
															list="unidad-list"
															value={aplicado ? viviendas.filter((vivienda) => vivienda.id_vivienda === line.Id_Vivienda)[0].numero_registro : this}
															onChange={(event) => {

																let valor = event.target.value;

																let unidad = viviendas.filter((vivienda) => vivienda.numero_registro.toLowerCase() === valor.toLowerCase());

																let lineas = linesReceipment.map((linea) => {

																	if (linea.Id_Forma_Cobro === line.Id_Forma_Cobro) {
																		linea.Edit = true;
																		linea.Id_Vivienda = 0;
																		linea.Text = valor;
																	};

																	return linea;

																});

																if (unidad[0]) {

																	lineas = linesReceipment.map((linea) => {

																		if (linea.Id_Forma_Cobro === line.Id_Forma_Cobro) {
																			linea.Id_Vivienda = unidad[0].id_vivienda;
																			linea.Edit = true;
																		};

																		return linea;
																	});

																};

																setLinesReceipment(lineas);
															}}
														/>
														<datalist id="unidad-list">
															<select className="input input-modals" name="conceptos">
																{viviendas.map((vivienda, key) => (
																	<option value={vivienda.numero_registro} key={key}></option>
																))}
															</select>
														</datalist>
													</React.Fragment>
												</td>
												<td className="text-center">
													<input
														id="fecha_pago"
														type="date"
														onKeyUp={addEventESC.bind(this)}
														className="input input-modals"
														value={line.Fecha_Pago}
														onClick={(e) => {

															const x = document.getElementById("fecha_pago");

															if (x) {
																x.classList.add("opened");
															};
														}}
														onMouseOut={(e) => {

															const x = document.getElementById("fecha_pago");

															if (x) {
																x.classList.remove("opened");
															};

														}}
														onChange={(e) => {

															let valor = e.target.value;

															let lineas = linesReceipment.map((linea) => {

																if (linea.Id_Forma_Cobro === line.Id_Forma_Cobro) {
																	linea.Fecha_Pago = moment(valor).format("YYYY-MM-DD");
																	linea.Edit = true;
																};

																return linea;
															});

															setLinesReceipment(lineas);
														}}
													/>
												</td>
												<td className="text-right">
													<NumberFormat
														className="input input-modals text-right"
														value={line.Monto}
														displayType={"number"}
														autoComplete="off"
														thousandSeparator={true}
														prefix={"$"}
														placeholder={'$'}
														decimalScale={2}
														fixedDecimalScale={true}
														disabled={aplicado}
														onChange={(event) => {

															let importeLinea = Number(event.target.value.replace(/\$/g, "").replace(/,/g, ""));

															let lineas = linesReceipment.map((linea) => {

																if (linea.Id_Forma_Cobro === line.Id_Forma_Cobro) {
																	linea.Monto = importeLinea;
																	linea.Edit = true;
																};

																return linea;
															});

															setLinesReceipment(lineas);
														}}
													/>
												</td>
												<td className="text-center">
													<select
														className="input input-modals"
														autoComplete="off"
														name="metodoPago"
														value={line.Id_FormaPago}
														disabled={aplicado}
														onChange={(event) => {

															let lineas = linesReceipment.map((linea) => {

																if (linea.Id_Forma_Cobro === line.Id_Forma_Cobro) {
																	linea.Id_FormaPago = Number(event.target.value);
																	linea.Edit = true;
																};

																return linea;
															});

															setLinesReceipment(lineas);
														}}
														required
													>
														<option value="0">Elija</option>
														{metodosPago.map((metodo, key) => (
															<option value={metodo.Id_FormaPago} key={key}>
																{metodo.Descripcion}
															</option>
														))}
													</select>
												</td>
												<td className="text-center">
													<input
														type="text"
														name="numMonths"
														className="input input-modals"
														autoComplete="off"
														value={line.BANCO}
														disabled={aplicado}
														onChange={(event) => {

															let lineas = linesReceipment.map((linea) => {

																if (linea.Id_Forma_Cobro === line.Id_Forma_Cobro) {
																	linea.BANCO = event.target.value;
																	linea.Edit = true;
																};

																return linea;
															});

															setLinesReceipment(lineas);
														}}
													/>
												</td>
												<td className="text-center">
													<input
														type="text"
														name="numMonths"
														className="input input-modals"
														maxLength="20"
														value={line.NO_MOVIMIENTO}
														disabled={aplicado}
														onChange={(event) => {

															let lineas = linesReceipment.map((linea) => {

																if (linea.Id_Forma_Cobro === line.Id_Forma_Cobro) {
																	linea.NO_MOVIMIENTO = event.target.value;
																	linea.Edit = true;
																};

																return linea;

															});

															setLinesReceipment(lineas);
														}}
													/>
												</td>
											</tr>
										</React.Fragment>
									);
								})
							)}
						</tbody>
						{loadingDetalle ? null : (
							<tfoot>
								<tr>
									<td className="border-left"></td>
									<td>
										<strong>Total</strong>
									</td>
									<td className="text-right">
										<NumberFormat
											value={linesReceipment.reduce((suma, linea) => suma + linea.Monto, 0)}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"$"}
											decimalScale={2}
											fixedDecimalScale={true}
										/>
									</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tfoot>
						)}
					</table>
				</div>
				<div className="row">
					<div className="white-space-8"></div>
				</div>
				<div className="row justify-end">
					{/* <button type="button" className="btn btn-primary btn-small color-white" onClick={addLines}>
						<i className="fas fa-plus font-text" />
						&nbsp; Agregar línea
					</button> */}
				</div>
			</React.Fragment>
		);
	};

	function reviewTable() {
		return (
			<React.Fragment>
				<div className="card-table card-owners full" style={{ overflow: "auto" }}>
					<table className=" full ">
						<thead>
							<tr>
								<th className="th-sticky text-center col-unidad">Vivienda</th>
								<th className="th-sticky text-center">Fecha</th>
								<th className="th-sticky text-center">Importe</th>
								<th className="th-sticky text-center col-metodo">Formas de pago</th>
								<th className="th-sticky text-center">Banco</th>
								<th className="th-sticky text-center">No. Movimiento</th>
								<th className="th-sticky text-center">Detalle</th>
							</tr>
						</thead>
						<tbody>
							{loadingDetalle ? (
								<tr>
									<td className="text-center" colSpan={"7"}>
										<div className="white-space-8"></div>
										<div className="row justify-center">
											<i className="fas fa-spinner fa-spin"></i>
										</div>
										<div className="white-space-8"></div>
									</td>
								</tr>
							) : (
								linesReceipment.map((line, key) => {
									return (
										<React.Fragment key={key}>
											<tr className={lineaSelected.Id_Forma_Cobro === line.Id_Forma_Cobro ? 'row-active' : ''}>
												<td className="text-center">{viviendas.filter((vivienda) => vivienda.id_vivienda === line.Id_Vivienda)[0].numero_registro}</td>
												<td className="text-center">{moment(line.Fecha_Pago).format("DD/MM/YYYY")}</td>
												<td className="text-right">
													<NumberFormat
														className="text-right"
														value={line.Monto}
														displayType={"text"}
														autoComplete="off"
														thousandSeparator={true}
														prefix={"$"}
														decimalScale={2}
														fixedDecimalScale={true}
													/>
												</td>
												<td className="text-center">{line.formaPago}</td>
												<td className="text-center">{line.BANCO}</td>
												<td className="text-center">{line.NO_MOVIMIENTO}</td>
												<td>
													<button
														className="btn-full btn-mini "
														type="button "
														onClick={() => { openRow(line) }}
													>
														<i className="font-small fas fa-info-circle" />
													</button>
												</td>
											</tr>

											{lineaSelected ? (
												lineaSelected.Id_Forma_Cobro === line.Id_Forma_Cobro ? (
													<tr className="acordeon-row">
														<td className="acordeon" colSpan="7">
															<div className="card-owners column full" style={{ maxHeight: 200, overflowY: "auto", position: "relative" }}>
																<table className="full" id="card-owners">
																	<thead>
																		<tr>
																			<th style={{ position: "sticky", top: 0 }} className="text-center">Folio</th>
																			<th style={{ position: "sticky", top: 0 }} className="text-center">Abono</th>
																			<th style={{ position: "sticky", top: 0 }} className="text-center">Forma Pago</th>
																		</tr>
																	</thead>
																	<tbody>
																		{lineaSelected.Detalle
																			? lineaSelected.Detalle.map((det, keyDet) => (
																				<tr key={keyDet}>
																					<td className="text-center">{det.NumFolio}</td>
																					<td className="text-center">
																						<NumberFormat
																							value={det.Importe_Abono}
																							displayType={"text"}
																							autoComplete="off"
																							thousandSeparator={true}
																							prefix={"$"}
																							decimalScale={2}
																							fixedDecimalScale={true}
																						/>
																					</td>
																					<td className="text-center">{det.formaPago}</td>
																				</tr>
																			))
																			: null}
																	</tbody>
																</table>
															</div>
															{line.comentarios &&
																<div className="column full">
																	<div className="white-space-16"></div>
																	<p>
																		<b>Comentarios:</b>
																	</p>
																	<div className="white-space-4" />
																	<textarea
																		className="textarea input input-modals"
																		name="pagoComentarios"
																		maxLength={500}
																		id="message"
																		value={line.comentarios}
																		readOnly={true}
																	></textarea>
																</div>
															}
														</td>
													</tr>
												) : null
											) : null}
										</React.Fragment>
									);
								})
							)}
						</tbody>
					</table>
				</div>
			</React.Fragment>
		);
	};

	return (
		<div className="container">
			<div className="column full">
				<div className="white-space-16"></div>
				{aplicado ? reviewTable() : newTable()}
			</div>
		</div>
	);
};

export default NewReceipment;