import Request from "../../../core/httpClient";

import { condonarTipos } from "./pagoTypes";

const request = new Request();

export const getDocumentosPendientesDeCobro = async (id_vivienda) => {
    try {
        const data = { id_vivienda };

        const response = await request.get("/documentos/get/pendientes/cobro", data);
    
        if (response.empty || response.error) {
    
            return { saldoAFavor: 0, documentosPendientes: [] };
    
        } else {
    
            const { docs, saldoFavor: saldoAFavor } = response;

            console.log("Docs: ", JSON.parse(docs));
    
            const documentosPendientes = JSON.parse(docs).map((doc) => {
    
                doc.DetalleRecargo = JSON.parse(doc.DetalleRecargo);
                doc.Recargos = 0;
                doc.DetalleDescuento = JSON.parse(doc.DetalleDescuento);
                doc.Descuento = 0;
    
                if (doc.EstadoCobro === 1) {
                    doc.Descuento = doc.DescuentoCobrado;
                    doc.Recargos = doc.RecargosCobrado;
                    doc.Total = doc.Importe - doc.DescuentoCobrado + doc.RecargosCobrado;
                    doc.Saldo = doc.Importe - doc.DescuentoCobrado + doc.RecargosCobrado - doc.Pagado;
                };

                const condonacionProps = doc.CondonacionCobrado && doc.CondonacionCobrado > 0 ? 
                    { 
                        Condonacion: doc.CondonacionCobrado, 
                        PrevCondonacion: doc.CondonacionCobrado, 
                        Condonar: condonarTipos.Previo,
                    }
                    : { 
                        Condonacion: 0, 
                        PrevCondonacion: 0, 
                        Condonar: condonarTipos.No, 
                    }
    
                return Object.assign(doc, {
                    checked: false,
                    ...condonacionProps,
                    PrevPagado: doc.Pagado,
                    Aplicado: 0,
                    Safg: 0, // Saldo a favor generado
                    Fecha: doc.MesAnyo.substring(0, 2) + "-" + doc.MesAnyo.substring(2, 6) 
                });
            });

            console.log("DOCS: ", documentosPendientes);
    
            return { documentosPendientes, saldoAFavor };
        }
    } catch (error) {
        console.log(error);
        return { error: "Error al obtener documentos pendientes" };
    }
}

export const postAplicarPagosDeDocumentosDeCobro = async (
    {
        id_vivienda, fechaRecibo, detallecobro, pagoInfo, abonos,
        saldoAfavor, userName, userId
    }
) => {

    try {

        const { fecha, idMetodoPago, importe, banco, numMovimiento, comentarios } = pagoInfo;

        const formapago = [{
            fechaLinea: fecha,
            idFormaPago: idMetodoPago,
            importePago: importe,
            bancoLinea: banco,
            movimientoLinea: numMovimiento,
            comentarios
        }];

        const data = { 
            id_vivienda, fechaRecibo, detallecobro, formapago,
            abonos, saldoAfavorGenerado: saldoAfavor, userName, userId,
        };

        const res = await request.post("/documentos/save/recibo/cobro", data);

        if (res.error || res.empty) {

            return { error: res.error || "No se pudo crear el recibo." }
            
        } else if (res.created) {

            return { created: true };
        };
    } catch (error) {
        return { error: "No se pudo crear el recibo." };
    }
}

export const postDocumentosPasswordManual = async (password) => {
    try {
        const data = {
            id_fraccionamiento: localStorage.getItem("frac") || 0,
            password,
        };
    
        const response = await request.get("/documentos/validar/aplicacion/manual", data);
    
        if (response) {
    
            localStorage.setItem("permisoManual", JSON.stringify({ permisoManual: response.valid }));
            return { valid: response.valid };
    
        } else {
    
            return { error: "Error de conexión" }
        }
    } catch (error) {
        console.log(error);
        return { error: "Error al checar password" };
    }
}