import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from "react-helmet";

// Context
import { Consumer } from '../../context';

// Components
import InformesControls from '../../components/controls/InformesControls';
import Header from '../../components/header/header';
import SidebarAdministracion from '../../components/header/sidebarAdministracion';
import PaginadorParent, { paginadorFilter } from '../../components/paginador/PaginadorParent';
import FechaPagosModal from '../CargosYPagos/components/FechaPagosModal';

// Requests
import { desgloseTipos } from './hooks/useInformesParameters';

const informesInfoInit = () => {
    const todayDate = new Date();

    const pagosFechasDesdeInit = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
    const pagosFechasHastaInit = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);

    return {
        informes: [],
        headersInfo: [],
        filters: {
            desglose: 0,
            vacios: 0,
            pagosFechas: { desde: pagosFechasDesdeInit, hasta: pagosFechasHastaInit },
        },
        tableSettings: {
            anclarColumnas: false,
            anclarFilas: true,
            desglosarTotales: true
        }
    }
};

const detalladoFilters = {
    vivsIds: ({ id_vivienda }, values) => values.indexOf(id_vivienda) !== -1,
    prodsIds: ({ id_producto }, values) => values.indexOf(id_producto) !== -1,
    foliosIds: ({ id_docCobro }, values) => {
        const folioIndex = values.indexOf(id_docCobro);
        folioIndex !== -1 && values.splice(folioIndex, 1);
        return folioIndex !== -1;
    },
    pagoEstado: ({ pago, saldo, id_docCobro }, value) => value !== 3 ? value !== 2 ? value !== 1 ?
        true
        : (saldo === 0 || pago > 0)
        : saldo > 0
        : id_docCobro === null
    ,
    pagosFechas: ({ pagos }, { desde, hasta }) => {

        for (let index = 0; index < pagos.length; index++) {
            const fechaPago = moment(pagos[index].fecha);
            const pagoIsBetweenFilter = fechaPago.isBetween(desde, hasta, "days", "[]");

            if (pagoIsBetweenFilter) return true;
        }

        return false;
    }
};

const concentradoFilters = {
    vivsIds: ({ id_vivienda }, values) => values.indexOf(id_vivienda) !== -1,
    vacios: ({ productos }, value) =>
        productos.some(({ pago }) => pago > 0)
};

const applyDetalladoFilters = ({ informes, filters }) => {

    const setDocFiltersToApply = () => Object.entries(filters).reduce((active, [key, value]) => {

        const filter = detalladoFilters[key];

        if(key === 'pagosFechas' && filters?.['pagoEstado'] === 2) return active;

        if (filter !== undefined) {

            const values = key === 'foliosIds' ? [...value] :
                key === 'pagosFechas' ? { desde: moment(value.desde), hasta: moment(value.hasta) }
                    : value;

            active.push({ filter, values });
        }

        return active;

    }, []);

    const safFiltersToApply = (() => {

        const { vivsIds } = filters;

        if(vivsIds) return [{ filter: detalladoFilters['vivsIds'], values: vivsIds }];

        return [];
    })();

    if(filters.pagoEstado && filters.pagoEstado === 3) {

        return informes.filter((informe) => 
            informe.id_docCobro === null 
                && safFiltersToApply.every(({ filter, values }) => filter(informe, values))
        );

    } else {

        const docFiltersToApply = setDocFiltersToApply();

        let lastViviendaId = -1;
    
        return informes.filter((informe) => {
    
            if(informe.id_docCobro !== null) {
    
                const passedFilter = docFiltersToApply.every(({ filter, values }) => filter(informe, values));
    
                if(passedFilter) lastViviendaId = informe.id_vivienda;
    
                return passedFilter;
    
            }
            else {
    
                const viviendaIdCopy = lastViviendaId;
    
                const passedFilter = safFiltersToApply.every(({ filter, values }) => filter(informe, values));
                if(passedFilter) lastViviendaId = -1;
    
                return passedFilter && !(viviendaIdCopy === -1 || viviendaIdCopy !== informe.id_vivienda);
            }
        });
    }

};

const applyConcentradoFilters = ({ informes, filters }) => {

    const filtersToApply = [];

    if (filters.vivsIds) {
        filtersToApply.push({ filter: concentradoFilters.vivsIds, values: filters.vivsIds })
    }

    if (filters.vacios === 0) {
        filtersToApply.push({ filter: concentradoFilters.vacios, values: filters.vacios })
    }

    return informes.filter((informe) =>
        filtersToApply.every(({ filter, values }) =>
            filter(informe, values)));
};

const setConcentradoTotalesFormat = (cargo = 0, descuento = 0, condonacion = 0, recargo = 0, pago = 0, total = 0) => {
    return { cargo, descuento, condonacion, recargo, pago, total }
};

const sumConcentradoTotales = (totales1, totales2) => {

    return setConcentradoTotalesFormat(
        totales1.cargo + totales2.cargo,
        totales1.descuento + totales2.descuento,
        totales1.condonacion + totales2.condonacion,
        totales1.recargo + totales2.recargo,
        totales1.pago + totales2.pago,
        totales1.total + totales2.total
    );
};

const getDetalladoTotalesYPagosActual = (informes, pagosFechas) => {

    const fechas = pagosFechas ? { 
        desde: moment(pagosFechas.desde), 
        hasta: moment(pagosFechas.hasta)
    } : null;

    const extraInfo = informes.reduce((info, documento) => {

        const { id_docCobro, pagos, pago, saf, descuento, condonacion, recargo, cargo, saldo } = documento;
        const { colTotales, pagosActual } = info;

        const getPagoActual = () => fechas ? pagos.reduce((acum, { fecha, pago }) => {
            
            const fechaPago = moment(fecha);
            const pagoIsBetweenFilter = fechaPago.isBetween(fechas.desde, fechas.hasta, "days", "[]");

            return pagoIsBetweenFilter ? acum + pago : acum;
        }, 0) : pago;

        const getSafActual = () => { 

            if(fechas) {
                
                const dateIsAfterIndex = saf.findIndex(({ fecha }) => {
                    const fechaPago = moment(fecha);
                    return fechaPago.isAfter(fechas.hasta, 'days');
                });

                return dateIsAfterIndex === -1 ? saf[saf.length - 1].saldo 
                        : dateIsAfterIndex === 0 ? 0
                        : saf[dateIsAfterIndex - 1].saldo
            } 
            else return saf[saf.length - 1].saldo;
        };

        const pagoActual = pagos.length > 0 ? getPagoActual()
            : saf.length > 0 ? getSafActual()
            : 0;

        pagosActual.push(pagoActual);

        colTotales.Descuentos += descuento;
        colTotales.Condonados += condonacion;
        colTotales.Recargos += recargo;
        colTotales.Importes += cargo;
        colTotales.Pagados += pagoActual;
        colTotales.Saldos += id_docCobro !== null ? saldo : -pagoActual;

        return info;

    }, {
        colTotales: { Importes: 0, Descuentos: 0, Condonados: 0, Recargos: 0, Pagados: 0, Saldos: 0 },
        pagosActual: []
    });

    return extraInfo;
}

const getConcentradoTotales = (informes, headersInfo) => {

    const totales = informes.reduce((totales, concentrado) => {

        const { rowTotales, colTotales } = totales;
        const { productos, id_vivienda, } = concentrado;

        headersInfo.forEach(({ id }) => {
            const producto = productos.find(({ id_producto }) => id_producto === id);
            colTotales[id_vivienda] = sumConcentradoTotales(colTotales[id_vivienda], producto);
            rowTotales[id] = sumConcentradoTotales(rowTotales[id], producto)
        });

        rowTotales['ColTotales'] = sumConcentradoTotales(rowTotales['ColTotales'], colTotales[id_vivienda]);

        return totales;

    }, {
        rowTotales: /* by producto  */
            headersInfo.reduce((totales, { id }) => { return { ...totales, [id]: setConcentradoTotalesFormat() } },
                { ColTotales: setConcentradoTotalesFormat() }),
        rowPerc: /* by producto */
            headersInfo.reduce((totales, { id }) => { return { ...totales, [id]: 0 } },
                { ColTotales: 0 }),
        colTotales: /* by vivienda */
            informes.reduce((totales, { id_vivienda }) => { return { ...totales, [id_vivienda]: setConcentradoTotalesFormat() } }, {})
    });

    if (informes.length > 0) {

        headersInfo.concat({ header: 'ColTotales', id: 'ColTotales' }).forEach(({ header, id }) => {
            const { pago, total } = totales.rowTotales[id];

            totales.rowPerc[id] = total ? (pago / total) * 100 : 0;
        });
    }

    return totales;
};

const initWidthPercentage = 0.8;
const containerWidthPercentage = 0.9;
const initWidthContent = initWidthPercentage * 100;

function Informes(props) {

    const { context, location } = props;
    const { getSubmenuAdminName, submenuAdminName } = context;

    const [informesInfo, setInformesInfo] = useState(informesInfoInit);
    const [tablesMaxHeight, setTablesMaxHeight] = useState(0);
    const [contentWidth, setContentWidth] = useState(initWidthContent + "%");

    const contentRef = useRef();
    const initContentHeight = useRef(null);

    const { informes, filters, headersInfo } = informesInfo;

    if(initContentHeight.current === null) {
        const contentElement = contentRef.current?.children[0];
        if(contentElement) initContentHeight.current = contentElement.clientHeight;
    };

    useEffect(() => {

        const sidebar = document.getElementById('sidebar');
        const informesTable = contentRef.current.querySelector('#informes-tables');

        const config = { attributes: true };

        const callback = (mutationList, observer) => {

            for (let index = 0; index < mutationList.length; index++) {
                const { attributeName, target } = mutationList[index];
                
                if(attributeName !== 'class') continue;

                if(target.className.includes('active')) {
                    const maxWidth = document.body.clientWidth * initWidthPercentage * containerWidthPercentage;
                    informesTable.style.setProperty("--table-max-width", maxWidth + "px");
                    setContentWidth(initWidthContent + "%");
                    return;
                }
            }

            const maxWidth = document.body.clientWidth * containerWidthPercentage;
            informesTable.style.setProperty("--table-max-width", maxWidth + "px");
            setContentWidth("100%");
        };

        const maxWidth = document.body.clientWidth * initWidthPercentage * containerWidthPercentage;
        informesTable.style.setProperty("--table-max-width", maxWidth + "px");

        const observer = new MutationObserver(callback);

        observer.observe(sidebar, config);

        return () => observer.disconnect();

    }, []);

    useEffect(() => {

        getSubmenuAdminName(location.pathname);

    }, [getSubmenuAdminName, location]);

    const onControlsSizeChange = ({ height }) => {

        const newMaxHeight = initContentHeight.current - height;
        setTablesMaxHeight(newMaxHeight);
    };

    const onFiltersChanged = (filters) => setInformesInfo(prevInfo => {

        const { headersInfo, ...otherFilters } = filters;

        const newInfo = { ...prevInfo };

        const newFilters = { ...newInfo.filters, ...otherFilters };

        Object.keys(newFilters).forEach((key) => newFilters[key] === undefined && delete newFilters[key]);

        newInfo.filters = newFilters;
        if (headersInfo) newInfo.headersInfo = headersInfo;

        return newInfo;
    });

    const onTableSettingsChanged = (settings) => setInformesInfo(prevInfo => {

        const newInfo = { ...prevInfo };
        const newSettings = { ...newInfo.tableSettings, ...settings };

        newInfo.tableSettings = newSettings;

        return newInfo;
    });

    const onInformesChanged = (informes, filters) => setInformesInfo(prevInfo => {

        const { headersInfo, ...otherFilters } = filters;

        const newInfo = { ...prevInfo };

        const newFilters = { ...newInfo.filters, ...otherFilters };

        Object.keys(newFilters).forEach((key) => newFilters[key] === undefined && delete newFilters[key]);

        newInfo.informes = informes;
        newInfo.filters = newFilters;
        newInfo.headersInfo = headersInfo;

        return newInfo;
    });

    const { enabledHeaders, filteredInformes, extraInfo } = useMemo(() => {

        const enabledHeaders = headersInfo ? headersInfo.filter((({ disabled }) => !disabled)) : [];

        const filteredInformes = informes.length === 0 ? null
            : filters.desglose === 0 ?
                applyDetalladoFilters({ informes, filters })
                : applyConcentradoFilters({ informes, filters });

        const extraInfo = filters.desglose === desgloseTipos.Detallado ?
            getDetalladoTotalesYPagosActual(filteredInformes || [], filters.pagosFechas) 
            : getConcentradoTotales(filteredInformes || [], enabledHeaders);

        return { enabledHeaders, filteredInformes, extraInfo };

    }, [informes, filters, headersInfo]);

    return (
        <div className="column" /*admin*/>
            <Helmet>
                <title>{process.env.REACT_APP_NAME} - {submenuAdminName}</title>
            </Helmet>
            <Header
                id='header'
                reload={() => console.log("Add reload")}
                change={() => console.log("Add change")}
                sidebar={true}
                nav={"administracion"}
            />
            <div className="row full">
                <div className="column" id="sidebar">
                    <SidebarAdministracion />
                </div>
                <div id="content"
                    ref={contentRef}
                    className="column align-center" 
                    style={{ width: contentWidth }}
                >
                    <div
                        className="container column"
                        style={{ flex: 1 }}
                    >
                        <InformesControls
                            title={submenuAdminName}
                            excelData={filteredInformes}
                            onSizeChange={onControlsSizeChange}
                            onInformes={onInformesChanged}
                            onFiltersChanged={onFiltersChanged}
                            onTableSettingsChanged={onTableSettingsChanged}
                        />
                        <InformesTableSelector
                            informes={filteredInformes}
                            desglose={informesInfo.filters.desglose}
                            pagosFechas={informesInfo.filters.pagosFechas}
                            enabledHeaders={enabledHeaders}
                            extraInfo={extraInfo}
                            tableSettings={informesInfo.tableSettings}
                            tablesMaxHeight={tablesMaxHeight}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consumer(Informes);

const InformesTableSelector = (props) => {

    const { informes, desglose, pagosFechas, enabledHeaders, extraInfo, tableSettings, tablesMaxHeight } = props;

    const [{ page, elementsPerPage }, setPaginadorInfo] = useState({ page: 0, elementsPerPage: 50 });

    const onPagedDataChanged = ({ page, elementsPerPage }) => setPaginadorInfo(prevInfo => {

        const { page: prevPage, elementsPerPage: prevElements } = prevInfo;

        if(page === prevPage && elementsPerPage === prevElements) return prevInfo;

        return { ...prevInfo, page, elementsPerPage };
    });

    const pagedInformes = paginadorFilter(informes || [], page, elementsPerPage);
    const pagedPagosActual = paginadorFilter(extraInfo.pagosActual || [], page, elementsPerPage);

    return (
        <div
            id="informes-tables"
            style={{
                display: "flex", flexDirection: "column", flex: 1, backgroundColor: "#FFF", zIndex: 0,
                maxHeight: tablesMaxHeight || "auto",
                transition: "all 300ms ease-in-out",
            }}
        >
            {informes !== null ?
                <>
                    <div className="column full informes-selector">
                        {desglose === desgloseTipos.Detallado ?
                            <DetalladoTable
                                informes={pagedInformes}
                                pagosFechas={pagosFechas}
                                tableSettings={tableSettings}
                                extraInfo={extraInfo}
                                pagosActual={pagedPagosActual}
                            />
                            : <ConcentradoTable
                                informes={pagedInformes}
                                tableSettings={tableSettings}
                                enabledHeaders={enabledHeaders}
                                extraInfo={extraInfo}
                            />
                        }
                    </div>
                    <PaginadorParent
                        data={informes}
                        initalElementsPerPage={50}
                        onPagedDataChange={onPagedDataChanged}
                    />
                </>
                :
                <div className='row full justify-center align-center' style={{ flex: 1 }}>
                    <h3>Sin documentos en busqueda</h3>
                </div>
            }
        </div>
    )
};

const DocumentoRow = (props) => {

    const { documento, index, pagoActual, onPagoInfoEnter } = props;
    const { id_docCobro, vivienda, num_folio, producto, mes_anyo,
        cargo, descuento, condonacion, recargo, saldo
    } = documento;

    const textAlignCenter = { textAlign: "center" };
    const textAlignLeft = { textAlign: "left" };
    
    return (
        <tr>
            <td style={textAlignLeft}>{vivienda}</td>
            <td style={textAlignLeft}>{num_folio}</td>
            <td style={textAlignLeft}>{producto}</td>
            <td style={{ whiteSpace: "nowrap", ...textAlignCenter }} >
                {mes_anyo}
            </td>
            <TableMoneyFormat value={cargo} />
            <TableMoneyFormat value={descuento} />
            <TableMoneyFormat value={condonacion} />
            <TableMoneyFormat value={recargo} />
            <td
                className="pagos-td text-right font-mini"
            >
                <div className='row' style={{ justifyContent: "flex-end", gap: 4 }} >
                    <MoneyFormat value={pagoActual} />
                    <button
                        className="btn-info"
                        type="button"
                        onClick={onPagoInfoEnter}
                        onPointerEnter={(e) => onPagoInfoEnter(e, index)}
                    >
                        <i className="font-small fas fa-info-circle pagos-i" style={{ display: "flex", alignSelf: "center" }} />
                    </button>
                </div>
            </td>
            <TableMoneyFormat value={saldo} />
        </tr>
    );
};

const SaldoAFavorRow = (props) => {

    const { documento, index, pagoActual, onPagoInfoEnter  } = props;

    const { id_vivienda, vivienda, producto, saf } = documento;

    const textAlignLeft = { textAlign: "left" };
    const borderRightNone = { borderRight: 'none' };

    return (
        <tr>
            <td style={textAlignLeft}>{vivienda}</td>
            <td style={borderRightNone}></td>
            <td colSpan={6} style={textAlignLeft}>{producto}</td>
            <td 
                className="pagos-td text-right font-mini" 
            >
                <div className='row' style={{ justifyContent: "flex-end", gap: 4 }} >
                    <MoneyFormat value={pagoActual} />
                    <button
                        className="btn-info"
                        type="button"
                        onPointerEnter={(e) => onPagoInfoEnter(e, index)}
                    >
                        <i className="font-small fas fa-info-circle pagos-i" style={{ display: "flex", alignSelf: "center" }} />
                    </button>
                </div>
            </td>
            <TableMoneyFormat value={-pagoActual} />            
        </tr>
    );
};

const DetalladoTable = (props) => {

    const { informes, tableSettings, extraInfo, pagosActual } = props;
    const { colTotales } = extraInfo;
    const { anclarFilas } = tableSettings;

    const [activeDocumentoPagos, setActiveDocumentoPagos] = useState({ index: -1, hovered: false });
    const [parentRect, setParentRect] = useState({ top: 0, left: 0, width: 0 });

    const currentPagos = informes[activeDocumentoPagos.index] ? 
        informes[activeDocumentoPagos.index].pagos.length > 0 ? 
        informes[activeDocumentoPagos.index].pagos : informes[activeDocumentoPagos.index].saf
        : [];

    const enableAnclarFilas = anclarFilas ? "anclar-filas-totales" : "";

    const tableClassName = `detallados-table ${enableAnclarFilas}`;

    const textAlignCenter = { textAlign: "center" };
    const textAlignLeft = { textAlign: "left" };

    useEffect(() => {

        if (!activeDocumentoPagos.hovered) return;

        const onPointerEnter = (e) => {

            const pagosTd = document
                .getElementsByClassName('detallados-table')[0]
                .querySelector('tbody')
                .children[activeDocumentoPagos.index]
                .getElementsByClassName('pagos-td')[0];

            const fechaPagosModal = document.getElementById('PagosYSafModal');

            const tdContains = pagosTd.contains(e.target);
            const modalContains = fechaPagosModal.contains(e.target);
            const isOtherIconHover = e.target.className.includes('pagos-i')

            const inHoverArea = tdContains || modalContains || isOtherIconHover;

            if(!inHoverArea) 
                setActiveDocumentoPagos(prevPagos => { return { ...prevPagos, hovered: false }});
        };

        document.addEventListener('pointerover', onPointerEnter);

        return () => document.removeEventListener('pointerover', onPointerEnter);

    }, [activeDocumentoPagos]);

    const onPagoInfoEnter = useCallback((e, index) => {

        const bounds = e.target.getBoundingClientRect();

        setActiveDocumentoPagos(prevPagos => {

            if (index !== prevPagos.index) setParentRect(bounds);
        
            return { index, hovered: true };
        });
    }, []);

    return (
        <>
            <table className={tableClassName}>
                <thead>
                    <tr>
                        <th>Vivienda</th>
                        <th>N° Folio</th>
                        <th>Producto</th>
                        <th style={textAlignCenter}>Mes</th>
                        <th style={textAlignCenter}>Importe</th>
                        <th style={textAlignCenter}>Descuento</th>
                        <th style={textAlignCenter}>Condonado</th>
                        <th style={textAlignCenter}>Recargos</th>
                        <th style={textAlignCenter}>Pago</th>
                        <th style={textAlignCenter}>Saldo</th>
                    </tr>
                </thead>
                <tbody>
                    {informes.length > 0 ?
                        <>
                            {informes.map((documento, index) => documento.num_folio === '' ?
                                <SaldoAFavorRow
                                    key={'saf-' + documento.id_vivienda}
                                    documento={documento}
                                    index={index}
                                    pagoActual={pagosActual[index]}
                                    onPagoInfoEnter={onPagoInfoEnter}
                                />
                                :
                                <DocumentoRow
                                    key={documento.id_docCobro}
                                    documento={documento} 
                                    index={index}
                                    pagoActual={pagosActual[index]}
                                    onPagoInfoEnter={onPagoInfoEnter}
                                />
                            )}
                            <tr>
                                <td colSpan={4} style={textAlignLeft}>Totales</td>
                                <TableMoneyFormat value={colTotales.Importes} />
                                <TableMoneyFormat value={colTotales.Descuentos} />
                                <TableMoneyFormat value={colTotales.Condonados} />
                                <TableMoneyFormat value={colTotales.Recargos} />
                                <TableMoneyFormat value={colTotales.Pagados} />
                                <TableMoneyFormat value={colTotales.Saldos} />
                            </tr>
                        </>
                        :
                        <tr className='empty-data-row'>
                            <td colSpan={10}
                                style={{ border: "unset", padding: 0 }}
                            >
                                <div >
                                    <h3>Sin documentos en filtrado</h3>
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            <PagosYSafModal
                isPagos={informes[activeDocumentoPagos.index]?.pagos.length > 0}
                pagos={currentPagos}
                open={activeDocumentoPagos.hovered}
                parentRect={parentRect}
            />
        </>
    )
};

const PagosYSafModal = (props) => {

    const { isPagos, pagos, open, parentRect } = props;

    const [currentPagos, setCurrentPagos] = useState(pagos);

    if(currentPagos !== pagos && pagos) {
        setCurrentPagos(pagos);
    }

    const PagosTable = () => {

        const hasComentarios = currentPagos.some(({ comentarios }) => comentarios !== "");

        return (
            <table style={{ maxWidth: 200 }}>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Pago</th>
                        {hasComentarios && <th>Comentarios</th>}
                    </tr>
                </thead>
                <tbody>
                    {currentPagos.map(({ fecha, pago, comentarios }, index) => (
                        <tr key={index}>
                            <td
                                className='font-mini text-right'
                                style={{ whiteSpace: "nowrap" }}
                            >
                                {moment(fecha).locale('es').format("D MMM YYYY").replace(".", "")}
                            </td>
                            <TableMoneyFormat value={pago} />
                            {hasComentarios &&
                                <td className='font-mini'>{comentarios || "--"}</td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const SafsTable = () => {

        return (
            <table style={{ maxWidth: 200 }}>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Pago</th>
                        <th>Saldo</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPagos.map(({ fecha, pago, saldo }, index) => (
                        <tr key={index}>
                            <td
                                className='font-mini text-right'
                                style={{ whiteSpace: "nowrap" }}
                            >
                                {moment(fecha).locale('es').format("D MMM YYYY").replace(".", "")}
                            </td>
                            <TableMoneyFormat value={pago} />
                            <TableMoneyFormat value={saldo} />
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <FechaPagosModal
            id={'PagosYSafModal'}
            open={open}
            style={{
                zIndex: 2, top: parentRect.top,
                left: parentRect.left + (parentRect.width / 2),
            }}
        >
            {currentPagos.length > 0 ?
                isPagos ?
                    <PagosTable />
                    :
                    <SafsTable />
                :
                <div className='full align-center' style={{ padding: "0.1rem 0.6rem" }}>
                    <b style={{ fontSize: "0.9rem", color: "white" }}>Sin Pagos</b>
                </div>
            }
        </FechaPagosModal>
    );
};

const moneyFormat = new Intl.NumberFormat('es-MX', {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2
});

const percentageFormat = new Intl.NumberFormat('es-MX', {
    style: 'percent',
    currency: "MXN",
    minimumFractionDigits: 2
});

const ConcentradoTable = (props) => {

    const { informes, tableSettings, enabledHeaders, extraInfo } = props;
    const { rowTotales, rowPerc, colTotales } = extraInfo;
    const { anclarColumnas, anclarFilas, desglosarTotales } = tableSettings;

    const tableRef = useRef();
    const prevOffsetsWidthRef = useRef(0);

    const enableAnclarColumnas = anclarColumnas ? "anclar-columnas-totales" : "";
    const enableAnclarFilas = anclarFilas ? "anclar-filas-totales" : "";
    const enableDesglosarTotales = desglosarTotales ? 'desglosar-totales' : 'no-desglosar-totales';

    const tableClassName = `concentrados-table ${enableAnclarColumnas} ${enableAnclarFilas} 
                            ${enableDesglosarTotales}`;

    const colSpan = desglosarTotales ? 5 : 2;

    const tableObserver = useCallback((entries) => {
        if (!entries[0]) return;

        const { target } = entries[0];
        const rowElement = target.querySelector('tbody tr');
        const lastTds = Array.from(rowElement.children).slice(-colSpan);

        const offsetsWidth = lastTds.reduce((total, td) => total + td.clientWidth, 0);

        if(offsetsWidth === prevOffsetsWidthRef.current) return;

        prevOffsetsWidthRef.current = offsetsWidth;

        let widthAcum = +Number(lastTds.pop().getBoundingClientRect().width).toFixed(2);

        lastTds.reverse().forEach((td, index) => {
            const width = +Number(td.getBoundingClientRect().width).toFixed(2);
            const property = '--offset-' + (index + 1);
            const value = widthAcum + "px";
            target.style.setProperty(property, value);
            widthAcum += width;
        });

    }, [colSpan]);

    useEffect(() => {

        if(enableAnclarColumnas === "") return;

        const observer = new ResizeObserver(tableObserver);
        observer.observe(tableRef.current, { box: "border-box" });

        return () => observer.disconnect();

    }, [enableAnclarColumnas, tableObserver]);

    const HeaderTdGroup = () => {

        const style = { fontStyle: "italic", fontWeight: "500" };

        return desglosarTotales ?
            <>
                <td style={style}>Importe</td>
                <td style={style}>Descuento</td>
                <td style={style}>Condonado</td>
                <td style={style}>Recargo</td>
                <td style={style}>Pago</td>
            </>
            :
            <>
                <td style={style}>Total</td>
                <td style={style}>Pago</td>
            </>
    };

    const TotalesTdGroup = ({ totales }) => {

        const { cargo, descuento, condonacion, recargo, total, pago } = totales;

        return desglosarTotales ? 
            <>
                <TableMoneyFormat value={cargo} />
                <TableMoneyFormat value={descuento} />
                <TableMoneyFormat value={condonacion} />
                <TableMoneyFormat value={recargo} />
                <TableMoneyFormat value={pago} />
            </>
            :
            <>
                <TableMoneyFormat value={total} />
                <TableMoneyFormat value={pago} />
            </>
    };

    return (
        <table ref={tableRef} className={tableClassName}>
            <thead>
                <tr>
                    <th style={{ textAlign: "left" }}>Vivienda</th>
                    {enabledHeaders.map(({ header }, index) => (
                        <th colSpan={colSpan} key={index}>{header}</th>
                    ))}
                    <th colSpan={colSpan}>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    {enabledHeaders.map((info, index) => (
                        <HeaderTdGroup key={index}/>
                    ))}
                    <HeaderTdGroup />
                </tr>
                {informes.length > 0 ?
                    <>
                        {informes.map(({ productos, id_vivienda, vivienda }, index) => (
                            <tr key={id_vivienda}>
                                <td style={{ fontSize: "0.93rem "}}>{vivienda}</td>
                                {enabledHeaders.map(({ id }, index) => {

                                    const producto = productos.find(({ id_producto }) => id_producto === id);
                                    return <TotalesTdGroup key={index} totales={producto} />
                                })}
                                <TotalesTdGroup totales={colTotales[id_vivienda]} />
                            </tr>
                        ))}
                        <tr>
                            <td style={{ fontSize: "0.93rem" }}>Totales</td>
                            {enabledHeaders.map(({ id }, index) => (
                                <TotalesTdGroup key={index} totales={rowTotales[id]} />
                            ))}
                            <TotalesTdGroup totales={rowTotales['ColTotales']} />
                        </tr>
                        <tr>
                            <td style={{ fontSize: "0.93rem" }}>Cobranza</td>
                            {enabledHeaders.map(({ id }, index) => (
                                <TablePercentageFormat key={index} value={rowPerc[id]} colSpan={colSpan} />
                            ))}
                            <TablePercentageFormat value={rowPerc['ColTotales']} colSpan={colSpan} />
                        </tr>
                    </>
                    :
                    <tr className='empty-data-row'>
                        <td colSpan={((enabledHeaders.length + 1) * colSpan) + 1}
                            style={{ border: "unset" }}
                        >
                            <div >
                                <h3>Sin documentos en filtrado</h3>
                            </div>
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    )
};

const MoneyFormat = ({ value }) => moneyFormat.format(parseFloat(value))

const TableMoneyFormat = ({ value }) => 

     <td className="text-right font-mini">
        {moneyFormat.format(parseFloat(value))}
    </td>


const TablePercentageFormat = ({ value, colSpan }) => 

     <td className='text-right' colSpan={colSpan}>
        {percentageFormat.format(parseFloat(value / 100))}
    </td>

