// React and third-parties
import React, { useState, useRef, useCallback } from 'react';
import cogoToast from 'cogo-toast';

// Types
import { condonarTipos, aplicacionEstadoTipos } from '../helpers/pagoTypes';

// Helpers
import { calcularRecargosYDescuentos, calcularPagoPrelacion2, SetDocPagoData } from '../helpers/pagoHelpers';

// Requests
import { getDocumentosPendientesDeCobro } from '../helpers/requests';

const toastConfig = { position: "bottom-right", hideAfter: 5 };

export const useDocumentosDeCobro = (id_vivienda, pagoInfo, aplicacionEstado) => {

    const currentIdVivienda = useRef(0); currentIdVivienda.current = id_vivienda;

    const [loadingDocumentos, setLoadingDocumentos] = useState(false);
    const [documentosDeCobro, setDocumentosDeCobro] = useState([]);
    const [saldoAFavor, setSaldoAFavor] = useState(0);

    const { importe } = pagoInfo;

    const aplicadosTotales = documentosDeCobro.reduce((totales, documento) => {

        const { Importe, Descuento, Recargos, Total,
            Pagado, Saldo, Aplicado, Condonacion, Condonar } = documento;

        if (Aplicado !== 0) {
            totales.Importes += Importe;
            totales.Descuentos += Descuento;
            totales.Recargos += Recargos;
            totales.Totales += Total;
            totales.Pagados += Pagado;
            totales.Saldos += Saldo;
            totales.Aplicados += Aplicado;
        }

        if (Condonar) {
            totales.Condonados += Condonacion;
        }

        return totales;

    }, { 
        Importes: 0, Descuentos: 0, Recargos: 0, 
        Totales: 0, Pagados: 0, Saldos: 0,
        Aplicados: 0, Condonados: 0 
    });

    const documentosTotales = documentosDeCobro.reduce((totales, documento) => {

        const { Saldo, Safg } = documento;

        totales.Saldos += Saldo;
        totales.Safgs += Safg;

        return totales;

    }, { Saldos: 0, Safgs: 0 });

    const { Saldos, Safgs } = documentosTotales;

    const saldoTotal = +documentosDeCobro.reduce((suma, doc) => suma + doc.Saldo, 0).toFixed(2);
    const safgTotal = +documentosDeCobro.reduce((suma, doc) => suma + doc.Safg, 0).toFixed(2);

    console.log("SAFG total: ", safgTotal);

    const resetCalculos = useCallback(() => setDocumentosDeCobro(prevDocumentos => 

        prevDocumentos.map(documento => new SetDocPagoData(documento).resetPagoData().documento)
    ), []);

	const loadDocumentosDeCobro = useCallback(async (search_id_vivienda) => {

		setLoadingDocumentos(true);

		const { documentosPendientes, saldoAFavor, error } = await getDocumentosPendientesDeCobro(search_id_vivienda);

        if(search_id_vivienda !== currentIdVivienda.current) return;

		if(error) {
			cogoToast.error(error, toastConfig);
		} else {
            const documentosCalculados = calcularRecargosYDescuentos(documentosPendientes, new Date());
			setDocumentosDeCobro(documentosCalculados);
			setSaldoAFavor(saldoAFavor);
		}

		setLoadingDocumentos(false);

	}, []);

    const checkDocAplicar = useCallback((index, checked) => setDocumentosDeCobro(prevDocumentos => {

        if(!prevDocumentos[index].checked && aplicadosTotales.Aplicados === importe) {

            cogoToast.warn("Importe seleccionado alcanzado", toastConfig);
            return prevDocumentos;
        }

        const documentosCopy = [...prevDocumentos];
        const newDoc = new SetDocPagoData(documentosCopy[index]).setDocAplicacion(checked, 0);
        const { Condonar } = documentosCopy[index];

        if (!checked) {

            if (Condonar === condonarTipos.Recargos)
                newDoc.resetDocCondonacion().resetDocTotales();

            if (Condonar === condonarTipos.Restante)
                newDoc.setDocCondonacion(Condonar, newDoc.saldoPreCondonar)
                    .setDocTotales(newDoc.totalPreCondonar - newDoc.saldoPreCondonar, 0);
        }

        documentosCopy[index] = newDoc.documento;

        return documentosCopy;
        
    }), [aplicadosTotales, importe]);

    const setDocAplicarCantidad = useCallback((index, aplicar) => setDocumentosDeCobro(prevDocumentos => {

        const documentosCopy = [...prevDocumentos];
        const newDoc = new SetDocPagoData(documentosCopy[index]);
        const { Pagado, checked } = newDoc.documento;

        const { applyCantidad, applyCondonar, applyCondonacion, applyTotal, reachedImporte } = newDoc.getApplyInfo(aplicar, aplicadosTotales.Aplicados, importe);

        if (reachedImporte) setUnappliedAsUnchecked(documentosCopy);

        documentosCopy[index] = applyCondonar !== condonarTipos.No ?
            newDoc
                .setDocAplicacion(checked, applyCantidad)
                .setDocTotales(applyTotal, applyTotal - Pagado)
                .setDocCondonacion(applyCondonar, applyCondonacion).documento
            :
            newDoc
                .setDocAplicacion(checked, applyCantidad)
                .resetDocTotales()
                .setDocCondonacion(applyCondonar, 0).documento;

        return documentosCopy;

    }), [aplicadosTotales, importe]);

    const setDocCondonarCantidad = useCallback((index, cantidad) => setDocumentosDeCobro(prevDocumentos => {

        const documentosCopy = [...prevDocumentos];
        const newDoc = new SetDocPagoData(documentosCopy[index]);
        const { Condonar } = newDoc.documento;

        const { applyCondonacion, applyCantidad, applyPagar, applyTotal } = newDoc.getApplyCondonacionInfo(cantidad, Saldos, pagoInfo.importe);

        documentosCopy[index] = newDoc
            .setDocAplicacion(applyCantidad !== 0, applyCantidad)
            .setDocTotales(applyTotal, applyTotal - applyPagar)
            .setDocPagado(applyPagar)
            .setDocCondonacion(Condonar, applyCondonacion).documento;

        return aplicacionEstado === aplicacionEstadoTipos.prelacion ?
            calcularPagoPrelacion2(documentosCopy, pagoInfo).documentosOrdenados
            : documentosCopy;

    }), [Saldos, aplicacionEstado, pagoInfo]);
    

    const selectDocCondonar = useCallback((index, condonar) => setDocumentosDeCobro(prevDocumentos => {
        
        const documentosCopy = [...prevDocumentos];
        const newDoc = new SetDocPagoData(documentosCopy[index]);

        const { totalPreCondonar, saldoPreCondonar } = newDoc
        const { Importe, Recargos, PrevPagado, Pagado, Aplicado, PrevCondonacion } = newDoc.documento;

        if (condonar === condonarTipos.No) {
            documentosCopy[index] = newDoc
                .setDocCondonacion(condonar, 0)
                .setDocPagado(PrevPagado)
                .setDocTotales(totalPreCondonar, saldoPreCondonar).documento;
        } 
        else {

            const cantidad = condonar === condonarTipos.Previo ? PrevCondonacion
                : condonar === condonarTipos.Recargos ? Recargos
                : condonar === condonarTipos.Restante ? newDoc.saldoPreCondonar - Aplicado
                : condonar === condonarTipos.Cantidad ? 0
                : -1; // Sin caso

            const condonacionInfo = newDoc.getApplyCondonacionInfo(cantidad, Saldos, pagoInfo.importe);
            const { applyCondonacion, applyCantidad, applyPagar, applyTotal } = condonacionInfo;

            documentosCopy[index] = newDoc
                .setDocAplicacion(applyCantidad !== 0, applyCantidad)
                .setDocTotales(applyTotal, applyTotal - applyPagar)
                .setDocPagado(applyPagar)
                .setDocCondonacion(condonar, applyCondonacion).documento;

        }

        return aplicacionEstado === aplicacionEstadoTipos.prelacion ?
            calcularPagoPrelacion2(documentosCopy, pagoInfo).documentosOrdenados
            : documentosCopy;

    }), [Saldos, pagoInfo, aplicacionEstado]);

    return { 
        loadingDocumentos,
        documentosDeCobro,
        saldoAFavor,
        aplicadosTotales,
        documentosTotales,
        saldoTotal,
        safgTotal,
        documentosActions: {
            loadDocumentosDeCobro,
            setDocumentosDeCobro, setSaldoAFavor,
            checkDocAplicar, setDocAplicarCantidad, setDocCondonarCantidad,
            selectDocCondonar,
            resetCalculos
        }
    };
};

const setUnappliedAsUnchecked = (documentos) => {
    for (let index = 0; index < documentos.length; index++) {
        const { Aplicado, checked } = documentos[index];
        
        if(checked && Aplicado === 0)
            documentos[index] = new SetDocPagoData(documentos[index]).resetDocAplicacion().documento;
    }
};
