import moment from "moment-timezone";

import { condonarTipos } from "./pagoTypes";

const checarCondonacionPreAbono = (setDocPagoData, importeRestante) => {

    if (setDocPagoData.documento.Condonar === 0) return;

    const { saldoPreCondonar } = setDocPagoData;
    const { Condonar, Pagado } = setDocPagoData.documento;

    if (Condonar === condonarTipos.Restante) {

        if(importeRestante >= saldoPreCondonar) {
            setDocPagoData.resetDocCondonacion().resetDocTotales();
        } else {
            setDocPagoData
                .setDocCondonacion(Condonar, saldoPreCondonar - importeRestante)
                .setDocTotales(importeRestante + Pagado, importeRestante)
        } 
    }
};

const aplicarAbonos = (documentos, importeRestante) => {

    for (let index = 0; index < documentos.length; index++) {
        
        const newDoc = new SetDocPagoData(documentos[index]);
        checarCondonacionPreAbono(newDoc, importeRestante);

        const { Saldo } = newDoc.documento;

        importeRestante = +(importeRestante - Saldo).toFixed(2);

        if(importeRestante >= 0) {
            documentos[index] = newDoc.setDocAplicacion(true, Saldo).documento;
        }

        if(importeRestante < 0) {
            documentos[index] = newDoc.setDocAplicacion(true, Saldo + importeRestante).documento;
            return { importeRestante: 0, lastIndex: index + 1 };
        }

        if(importeRestante === 0) return { importeRestante: 0, lastIndex: index + 1 };
    }

    return { importeRestante, lastIndex: documentos.length };
};

const resetearNoAplicados = (documentos, startIndex = 0) => {
    
    for (let index = startIndex; index < documentos.length; index++) {

        documentos[index] = new SetDocPagoData(documentos[index]).resetPagoData().documento;
    }
};

export const setAbonoFormat = (Id_DocCobro, Abono, FechaAbono, formasCobro) => { 
    return { Id_DocCobro, Abono, FechaAbono, formasCobro }; 
};

export const calcularRecargosYDescuentos = (documentos, fecha) => {

    const fechaPago = moment(fecha).tz("GMT");

    const newDocumentosPendientes = documentos.map((documento) => {

        const { Importe, PrevPagado, Pagado, DetalleRecargo, DetalleDescuento, PrevCondonacion } = documento;
        let calculoDescuento = 0;

        const descuentosSelected = DetalleDescuento.map((descuento) => {

            const { fecha, importe } = descuento;
            const fechaDescuento = moment(fecha).tz("GMT");

            if(calculoDescuento === 0) {
                const select = fechaDescuento.isSameOrAfter(fechaPago, "days");
                calculoDescuento = select ? importe : 0;
                return { ...descuento, selected: select };
            }
            else {
                return { ...descuento, selected: false };
            }
        });

        const calculoRecargos = DetalleRecargo.reduce((total, { fecha, importe }) => {

            const fechaRecargo = moment(fecha, "YYYY-MM-DD").tz("GMT");
            
            return fechaRecargo.isSameOrBefore(fechaPago, "days") ?
                total + importe : total;
        }, 0).toFixed(2);

        const Descuento = parseFloat(calculoDescuento.toFixed(2));
        const Recargos = parseFloat(calculoRecargos);

        const newDoc = { 
            ...documento, 
            DetalleDescuento: descuentosSelected,
            Descuento,
            Recargos
        };

        const { Total, Saldo } = SetDocPagoData.getTotales(newDoc);

        newDoc.Total = Total;
        newDoc.Saldo = Saldo;

        return newDoc;
    });

    return newDocumentosPendientes;
};

export const calcularPagoPrelacion = (documentos, { importe, fecha }) => {

    let importeRestante = importe;
    let documentosVencidos = [];
    const documentosNoVencidos = {};
    let headersInfo = {};

    const segmentarDocumentos = () => {

        [...documentos].forEach((documento) => {

            const { Fecha, Descuento } = documento;

            const fechaPago = moment(fecha).tz("GMT");
            const fechaDocumento = moment(Fecha, "MM-YYYY").tz("GMT");

            if (fechaDocumento.isBefore(fechaPago, "months")) {
                documentosVencidos.push(documento);
            }

            else if (fechaDocumento.isSame(fechaPago, "months") && Descuento === 0) {
                documentosVencidos.push(documento);
            }

            else {

                if (!Object.keys(documentosNoVencidos).find((key) => key === Fecha)) {
                    documentosNoVencidos[Fecha] = [];
                } 

                documentosNoVencidos[Fecha].push(documento);
            }
        });
    };

    const setHeadersInfo = (index, title) => { headersInfo[index] = title };

    const concatenarDocumentos = () => {

        if(documentosVencidos.length > 0) setHeadersInfo(0, "Vencidos");

        return Object.entries(documentosNoVencidos).reduce((newDocumentos, [key, documentos], index) => {
            
            setHeadersInfo(
                index === 0 ? documentosVencidos.length : newDocumentos.length, 
                moment(key, "MM-YYYY").locale('es').format("MMMM YYYY").replace(".", "")
            );

            return newDocumentos.concat(documentos);
        }, documentosVencidos);
    }

    segmentarDocumentos();

    documentosVencidos = documentosVencidos.sort(
        (docA, docB) => docA.Prelacion - docB.Prelacion
    );

    Object.entries(documentosNoVencidos).forEach(([key, documentos]) => {

        documentosNoVencidos[key] = documentos.sort((docA, docB) => {
            const descuentoDiff = docB.Descuento - docA.Descuento;

            if (descuentoDiff !== 0) return descuentoDiff;

            return docA.Prelacion - docB.Prelacion;
        });
    });

    const documentosOrdenados = concatenarDocumentos();

    const { importeRestante: saldoAFavorGenerado, lastIndex } = aplicarAbonos(documentosOrdenados, importeRestante);

    return { saldoAFavorGenerado, documentosOrdenados, headersInfo };
};

export const calcularPagoPrelacion2 = (documentos, { importe, fecha }) => {

    let importeRestante = importe;
    let documentosVigentes = [];
    let documentosActuales = [];
    const documentosProximos = {};
    let headersInfo = {};

    const segmentarDocumentos = () => {

        [...documentos].forEach((documento) => {

            const { Fecha } = documento;

            const fechaPago = moment(fecha).tz("GMT");
            const fechaDocumento = moment(Fecha, "MM-YYYY").tz("GMT");

            if (fechaDocumento.isBefore(fechaPago, "months")) {
                documentosVigentes.push(documento);
            }

            else if (fechaDocumento.isSame(fechaPago, "months")) {
                documentosActuales.push(documento);
            }

            else {

                if (!Object.keys(documentosProximos).find((key) => key === Fecha)) {
                    documentosProximos[Fecha] = [];
                } 

                documentosProximos[Fecha].push(documento);
            }
        });
    };

    const setHeadersInfo = (index, title) => { headersInfo[index] = title };

    const concatenarDocumentos = () => {

        if(documentosVigentes.length > 0) setHeadersInfo(0, "Vigentes");

        return Object.entries(documentosProximos).reduce((newDocumentos, [key, documentos], index) => {
            
            setHeadersInfo(
                index === 0 ? documentosVigentes.length : newDocumentos.length, 
                moment(key, "MM-YYYY").locale('es').format("MMMM YYYY").replace(".", "")
            );

            return newDocumentos.concat(documentos);
        }, documentosVigentes);
    }

    segmentarDocumentos();

    const descuentoEnActuales = documentosActuales.some((documento) => documento.Descuento > 0);
    const saldoMesesPrevios = documentosVigentes.reduce((total, documento) => total + documento.Saldo, 0);

    if (descuentoEnActuales && saldoMesesPrevios <= importe) {

        documentosVigentes.sort((docA, docB) => { 
            const fechaA = moment(docA.Fecha, "MM-YYYY").tz("GMT").get('years');
            const fechaB = moment(docB.Fecha, "MM-YYYY").tz("GMT").get('years');

            const fechaDiff = fechaA - fechaB;

            return fechaDiff !== 0 ? fechaDiff : docA.Prelacion - docB.Prelacion 
        });
        
        documentosActuales.sort((docA, docB) => {
            const descuentoDiff = docB.Descuento - docA.Descuento;

            if (descuentoDiff !== 0) return descuentoDiff;

            return docA.Prelacion - docB.Prelacion;
        });

        documentosVigentes = documentosVigentes.concat(documentosActuales);

    } else {

        documentosVigentes = documentosVigentes.concat(documentosActuales).sort((docA, docB) => { 
            const fechaA = moment(docA.Fecha, "MM-YYYY").tz("GMT").get('years');
            const fechaB = moment(docB.Fecha, "MM-YYYY").tz("GMT").get('years');

            const fechaDiff = fechaA - fechaB;

            return fechaDiff !== 0 ? fechaDiff : docA.Prelacion - docB.Prelacion 
        });
    }

    Object.entries(documentosProximos).forEach(([key, documentos]) => {

        documentosProximos[key] = documentos.sort((docA, docB) => {
            const descuentoDiff = docB.Descuento - docA.Descuento;

            if (descuentoDiff !== 0) return descuentoDiff;

            return docA.Prelacion - docB.Prelacion;
        });
    });

    const documentosOrdenados = concatenarDocumentos();

    const { importeRestante: saldoAFavorGenerado, lastIndex } = aplicarAbonos(documentosOrdenados, importeRestante);

    resetearNoAplicados(documentosOrdenados, lastIndex);

    return { saldoAFavorGenerado, documentosOrdenados, headersInfo };
};

export class SetDocPagoData {

    constructor (documento) {
        this.documento = { ...documento };

        const { totalPreCondonar, saldoPreCondonar } = SetDocPagoData.getTotalesPreCondonar(this.documento);
        this.totalPreCondonar = totalPreCondonar;
        this.saldoPreCondonar = saldoPreCondonar;
    }

    static getTotales(documento) {

        const { Importe, Descuento, Recargos, PrevCondonacion, PrevPagado } = documento;

        console.log("PRev Condonacion Recargos:", Recargos);

        const Total = +(Importe + Recargos - Descuento - PrevCondonacion).toFixed(2);
        const Saldo = +(Total - PrevPagado).toFixed(2);

        return { Total, Saldo };
    }

    static getTotalesPreCondonar(documento) {

        const { Importe, Descuento, Recargos, PrevPagado } = documento;

        const totalPreCondonar = +(Importe + Recargos - Descuento).toFixed(2);
        const saldoPreCondonar = +(totalPreCondonar - PrevPagado).toFixed(2);

        return { totalPreCondonar, saldoPreCondonar };
    }

    setDocCondonacion = function (Condonar, Condonacion) {
        this.documento.Condonar = Condonar;
        this.documento.Condonacion = +Condonacion.toFixed(2);
        return this;
    }

    resetDocCondonacion = function () {

        const { PrevCondonacion } = this.documento;

        this.documento.Condonar = PrevCondonacion ? condonarTipos.Previo : condonarTipos.No;
        this.documento.Condonacion = PrevCondonacion;
        return this;
    }

    setDocTotales = function (Total, Saldo) {
        this.documento.Total = +Total.toFixed(2);
        this.documento.Saldo = +Saldo.toFixed(2);
        return this;
    }

    resetDocTotales = function () {

        const { PrevCondonacion } = this.documento;

        const newTotales = PrevCondonacion > 0 ?
            SetDocPagoData.getTotales(this.documento)
            : { Total: this.totalPreCondonar, Saldo: this.saldoPreCondonar };

        this.documento.Total = newTotales.Total;
        this.documento.Saldo = newTotales.Saldo;

        return this;
    }
    
    setDocAplicacion = function (checked, Aplicado) {
        this.documento.checked = checked;
        this.documento.Aplicado = +Aplicado.toFixed(2);
        return this;
    }

    resetDocAplicacion = function () {
        this.documento.checked = false;
        this.documento.Aplicado = 0;
        return this;
    }

    setDocPagado = function (pagado) {
        this.documento.Pagado = pagado;
        this.documento.Safg = +(this.documento.PrevPagado - pagado).toFixed(2);
        return this;
    }

    resetDocPagado = function () {
        this.documento.Pagado = this.documento.PrevPagado;
        this.documento.Safg = 0;
        return this;
    }

    resetPagoData = function () {
        return this.resetDocCondonacion()
            .resetDocAplicacion()
            .resetDocPagado()
            .resetDocTotales();
    }

    getApplyInfo = function (aplicar, aplicados, importe) {

        const { Aplicado, Condonar, Condonacion, Saldo } = this.documento;
        const otherAplicados = +(aplicados - Aplicado).toFixed(2);
        const cantidadRemaining = +(importe - otherAplicados).toFixed(2);

        const saldoToApply = Condonar !== condonarTipos.Restante ? Saldo : this.saldoPreCondonar;
        const applyCantidad = Math.min(aplicar, cantidadRemaining, saldoToApply);
        const applyCondonar = applyCantidad === this.saldoPreCondonar ? condonarTipos.No : Condonar;
        const applyCondonacion = Condonar !== condonarTipos.Restante ? 
            Condonacion : this.saldoPreCondonar - applyCantidad;
        
        const applyTotal = +(this.totalPreCondonar - applyCondonacion).toFixed(2);

        const newTotal = otherAplicados + applyCantidad;
        const reachedImporte = importe === +newTotal.toFixed(2);

        return { applyCantidad, applyCondonar, applyCondonacion, applyTotal, reachedImporte };
    }

    getApplyCondonacionInfo = function (aplicar, saldos, importe) {

        const { Saldo, PrevPagado, Aplicado } = this.documento;
        const otherSaldos = +(saldos - Saldo).toFixed(2);
        const cantidadRemaining = +(this.saldoPreCondonar - (importe - otherSaldos)).toFixed(2);

        const applyCondonacion = Math.min(aplicar, cantidadRemaining, this.totalPreCondonar);
        const applyPagar = +(PrevPagado - Math.max(0, applyCondonacion - this.saldoPreCondonar)).toFixed(2);
        const applyTotal = +(this.totalPreCondonar - applyCondonacion).toFixed(2);
        const applyCantidad = Math.max(0, Math.min(+(applyTotal - applyPagar).toFixed(2), +(Aplicado)).toFixed(2));//+(this.saldoPreCondonar - applyCondonar).toFixed(2);

        return { applyCantidad, applyCondonacion, applyPagar, applyTotal };
    }
}