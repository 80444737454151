import React, { useState } from 'react';

export default function PaginadorParent(props) {

    const { showElementsPerPage = true, data = [], initalElementsPerPage = 5, onPagedDataChange } = props;

    const [newData, setNewData] = useState(data);
    const [currentPage, setCurrentPage] = useState(0);
    const [elementsPerPage, setElementsPerPage] = useState(initalElementsPerPage);

    const pagesLength = Math.ceil(data.length / elementsPerPage);

    const { pageStart, pageEnd } = setPagesBounds(currentPage, pagesLength);

    const changedPageDataHandler = (page = currentPage, newElementsPerPage = elementsPerPage) => {

        onPagedDataChange && onPagedDataChange({
            page,
            elementsPerPage: newElementsPerPage,  
            pagedData: paginadorFilter(data, page, newElementsPerPage)
        });
    };

    const changeElementsPerPage = (newElementsPerPage) => {

        setElementsPerPage(newElementsPerPage);
        changeCurrentPage(0, newElementsPerPage);
    };

    const changeCurrentPage = (newCurrentPage, newElementsPerPage = elementsPerPage) => {

        setCurrentPage(newCurrentPage);
        changedPageDataHandler(newCurrentPage, newElementsPerPage);
    };

    if (data !== newData) {
        setNewData(data);

        if (currentPage > pageEnd - 1) {
            changeCurrentPage(pageEnd - 1);
        } 
        else if (currentPage === -1) {
            changeCurrentPage(0);
        }
        else {
            changedPageDataHandler();
        }
    }

    return (
        <div className="paginator justify-center">
            <div className="row full">
                <div className="column full">
                    <div className="white-space-8"></div>
                </div>
                <div className="column full justify-center">
                    <div className="row justify-center">
                        <span
                            className="prev page"
                            onClick={() => changeCurrentPage(0)}
                            style={{ visibility: currentPage !== 0 ? "visible" : "hidden" }}
                        >
                            {"<"}
                        </span>
                        {Array(pagesLength <= 5 ? pagesLength : 5).fill().map((page, index) => {

                            const pageNum = pageStart + index;

                            return (
                                <span
                                    key={index}
                                    onClick={() => changeCurrentPage(pageNum - 1)}
                                    className={`${currentPage === (pageNum - 1) ? "active page" : "page"}`}
                                >
                                    {pageNum}
                                </span>
                            )
                        })}
                        <span
                            className="next page"
                            onClick={() => changeCurrentPage(pagesLength - 1)}
                            style={{ visibility: currentPage + 1 !== pagesLength ? "visible" : "hidden" }}>
                            {">"}
                        </span>
                        
                    </div>
                </div>
                <div className="column full">
                    <div className="row full justify-end">
                        {showElementsPerPage &&
                            <select
                                className="input input-select"
                                value={elementsPerPage}
                                onChange={(event) => changeElementsPerPage(+event.target.value)}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        }
                    </div>
                </div>
            </div>
        </div>)
}

export const paginadorFilter = (data, currentPage, currentElemetsPerPage) => {

    return data.slice(currentPage * currentElemetsPerPage, (currentPage + 1) * currentElemetsPerPage);
};

const setPagesBounds = (currentPage, pagesLength) => {

    const currentPageNum = currentPage + 1;

    if(currentPageNum <= 4) {
        return { pageStart: 1, pageEnd: pagesLength }
    }

    if(currentPageNum + 1 >= pagesLength) {
        return { pageStart: pagesLength - 4, pageEnd: pagesLength }
    }

    return { pageStart: currentPageNum - 2, pageEnd: currentPageNum + 2 };
};
