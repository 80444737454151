// React and third-parties
import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react';
import NumberFormat from 'react-number-format';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

// Types
import { calculoTipos, aplicacionEstadoTipos, condonarTipos, condonarModes } from '../helpers/pagoTypes';

// Validations
import { validateAplicacion, validatePagoInfo } from '../helpers/validations';

// Helpers
import { setAbonoFormat, SetDocPagoData,
    calcularRecargosYDescuentos, calcularPagoPrelacion2 } from '../helpers/pagoHelpers';
import { waitForElement } from '../../../helpers/waitForElement';

// Hooks
import { useDocumentosDeCobro } from '../hooks/useDocumentosDeCobro';

// Components
import CheckBox from '../../../components/toggles/CheckBox';
import SuperCore from '../../../components/core/SuperCore';
import SuperModal from '../../../components/coreModal/SuperModal';
import CurrencyFormatInput from './CurrencyFormatInput';

// Requests
import { postAplicarPagosDeDocumentosDeCobro, 
    postDocumentosPasswordManual } from '../helpers/requests';

registerLocale("es", es);

const modal = new SuperCore();

const toastConfig = { position: "bottom-right", hideAfter: 5 };

const setPagoInfoInit = (prelacion) => {
    return {
        fecha: new Date(),
        idMetodoPago: 0,
        importe: 0,
        banco: "",
        numMovimiento: "",
        comentarios: "",
        passwordAplicada: false,
        calculoTipo: prelacion ? calculoTipos.prelacion : calculoTipos.manual,
        saldoAFavorGenerado: 0
    }
};

const prelacionHeaderOptions = [
    { id: 0, option: 'Seleccionar', disabled: false },
    { id: 2, option: 'Recargos', disabled: false },
    { id: 3, option: 'Documento restante', disabled: false }
];

const manualHeaderOptions = [
    { id: 0, option: 'Seleccionar', disabled: false },
    { id: 2, option: 'Recargos', disabled: false }
];

const manualOptions = [
    { id: 0, option: 'No condonar', disabled: false },
    { id: 1, option: 'Previo', disabled: true },
    { id: 2, option: 'Recargos', disabled: false },
    { id: 3, option: 'Restante', disabled: false }
];

const prelacionOptions = [
    { id: 0, option: 'No condonar', disabled: false },
    { id: 1, option: 'Previo', disabled: true },
    { id: 2, option: 'Recargos', disabled: false },
    { id: 3, option: 'Restante', disabled: false },
    { id: 4, option: 'Cantidad', disabled: true }
];

const condonarSelectOptions = [
    { id: 0, option: 'No condonar', disabled: false },
    { id: 1, option: 'Previo', disabled: true },
    { id: 2, option: 'Recargos', disabled: false },
    { id: 3, option: 'Restante', disabled: false },
    { id: 4, option: 'Cantidad', disabled: false }
];

export default function AplicarPagoModal(props) {

    const { viviendaInfo: { unidad, id_vivienda }, userInfo: { userName, userId },
        enabledPrelacion, metodosPago, onCloseModal, onSuccessfulSubmit } = props;

    const initDateSelected = useRef();
    const currentIdVivienda = useRef(0); currentIdVivienda.current = id_vivienda;
    const onValidPasswordActionRef = useRef(null);

	const [loadingPago, setLoadingPago] = useState(false);
	const [loadingPasswordSubmit, setLoadingPasswordSubmit] = useState(false);

	const [aplicacionEstado, setAplicacionEstado] = useState(aplicacionEstadoTipos.inicial);
	const [pagoInfo, setPagoInfo] = useState(() => setPagoInfoInit(enabledPrelacion));
	const [passwordText, setPasswordText] = useState("");
    const [passwordAplicada, setPasswordAplicada] = useState(false);
    const [enabledCondonar, setEnabledCondonar] = useState(false);

    const [headersInfo, setHeadersInfo] = useState([]);
    const [tableStyleInfo, setTableStyleInfo] = useState({ headersInfoMaxWidth: 0, enableConceptShadow: false });

    const {
        loadingDocumentos,
        documentosDeCobro: documentosPendientes,
        saldoAFavor,
        documentosTotales,
        aplicadosTotales, saldoTotal,
        documentosActions
    } = useDocumentosDeCobro(id_vivienda, pagoInfo, aplicacionEstado);

    const { 
        loadDocumentosDeCobro,
        setDocumentosDeCobro: setDocumentosPendientes,
        setSaldoAFavor, resetCalculos
    } = documentosActions;

	const metodosPagoFormat = metodosPago.map((metodo) => {
		if(metodo.Id_FormaPago === -1 && saldoAFavor === 0) {
			return { ...metodo, disabled: true };
		} else {
			return metodo;
		}
	});
    
    metodosPagoFormat.push({ Clave: "05", Descripcion: "Solo Condonar", Id_FormaPago: 22, disabled: !enabledCondonar });

    const { headerOptions, dataOptions } = aplicacionEstado === aplicacionEstadoTipos.prelacion ?
        { headerOptions: prelacionHeaderOptions, dataOptions: prelacionOptions }
        : { headerOptions: manualHeaderOptions, dataOptions: manualOptions };

    const tableUpdate = (() => {

        const dateSelected = pagoInfo.fecha.toLocaleDateString();
        if(dateSelected !== initDateSelected.current) {
            initDateSelected.current = undefined;
            return dateSelected;
        }
        else {
            return "";
        }
    })();

    const resetForm = useCallback(() => {

        const pagoInfoInit = setPagoInfoInit(enabledPrelacion);
		setPagoInfo(pagoInfoInit);
		setAplicacionEstado(aplicacionEstadoTipos.inicial);
		setSaldoAFavor(0);
        setPasswordAplicada(false);
        setEnabledCondonar(false);

        initDateSelected.current = pagoInfoInit.fecha.toLocaleDateString();

	}, [enabledPrelacion, setSaldoAFavor]);

    const setTableResizeObserver = () => {

        const tableDiv = document.getElementById("newPayment").getElementsByClassName("table-apply-payment")[0];

        const divObserver = new ResizeObserver((entries) => {
            if (entries[0]) {
                const { contentRect, target } = entries[0];
                setTableStyleInfo(({ headersInfoMaxWidth }) => { return { 
                    headersInfoMaxWidth: contentRect ? contentRect.width : headersInfoMaxWidth,
                    enableConceptShadow: target.clientWidth < target.scrollWidth
                }});
            }
        });

        divObserver.observe(tableDiv, { box: "border-box" });

        return divObserver;
    };

	useEffect(() => {
	  
		if(id_vivienda !== 0) {
            resetForm();
			modal.superOpenModal("newPayment");
			loadDocumentosDeCobro(id_vivienda);
		} 

	}, [loadDocumentosDeCobro, resetForm, id_vivienda]);

    useLayoutEffect(() => {
       
        if(id_vivienda !== 0) {

            const observer = setTableResizeObserver();
            return () => observer.disconnect();
        }

    }, [id_vivienda]);

	const loadSubmitPago = async () => {

		const setSubmitPagosAplicados = async () => {

			const { fecha, saldoAFavorGenerado, idMetodoPago } = pagoInfo;

			const { error: errorAplicacion } = validateAplicacion(documentosPendientes, aplicacionEstado, pagoInfo);

			if(errorAplicacion) {
				cogoToast.error(errorAplicacion, toastConfig);
				return false;
			}

            const fechaPago = moment(fecha).tz("GMT").toDate();

			const detalles = documentosPendientes
                .filter((documento) =>
                    documento.Aplicado !== 0
                    || (documento.Condonar && documento.Condonar !== condonarTipos.Previo)
                )
                .map((documento) => { return { ...documento }});

			const abonos = detalles.map(({ Id_DocCobro, Aplicado }) => {
				return setAbonoFormat(Id_DocCobro, Aplicado, fechaPago, idMetodoPago)
            });

            // Si suman, pero solo es mayor que cero uno de los dos valores
            const SAFG = +(saldoAFavorGenerado + documentosTotales.Safgs).toFixed(2);

			const data = { 
				id_vivienda, fechaRecibo: new Date(), detallecobro: detalles,
				pagoInfo, abonos, saldoAfavor: SAFG,
				userName, userId
			};

			const { created, error: errorSubmit } = await postAplicarPagosDeDocumentosDeCobro(data);
	
			if(errorSubmit) {
				cogoToast.error(errorSubmit, toastConfig);
				return false;
			}
	
			if(created) {
				cogoToast.success("Se creó el recibo de manera correcta.", toastConfig);
                return true;
			}
		};

		setLoadingPago(true);

		const submitted = await setSubmitPagosAplicados();

		setLoadingPago(false);

        if(submitted) {
            modal.superCloseModal("newPayment");
            onSuccessfulSubmit();
        }
	};

    const openPasswordModal = (onValidPasswordAction) => {

        const passwordInput = document.getElementById("passwordManual").getElementsByTagName("input").namedItem("password");

        onValidPasswordActionRef.current = onValidPasswordAction;

        modal.superOpenModal("passwordManual");
        setTimeout(() => passwordInput.focus(), 100);
    };

	const loadSubmitPassword = async () => {

		setLoadingPasswordSubmit(true);

		const { valid, error } = await postDocumentosPasswordManual(passwordText);

		if (error) {
			cogoToast.error(error, toastConfig);
			setAplicacionEstado(aplicacionEstadoTipos.inicial);
		} 
		
		if(valid) {
            setPasswordAplicada(true);
            onValidPasswordActionRef.current();
		}
		else {
			cogoToast.error("La contraseña es incorrecta.", toastConfig);
		}

		modal.superCloseModal("passwordManual");

		setPasswordText("")
		setLoadingPasswordSubmit(false);
	};

	const setRecargosYDescuentos = (fecha) => {

		const newDocumentosPendientes = calcularRecargosYDescuentos(documentosPendientes, fecha);

		setDocumentosPendientes(newDocumentosPendientes);
		setPagoInfo(prevInfo => { return { ...prevInfo, fecha }});
	};

    const onEnableCondonar = (checked) => {

        if(!passwordAplicada && checked) 
            openPasswordModal(() => setEnabledCondonar(true));
        else {
            setEnabledCondonar(checked);
            if(pagoInfo.idMetodoPago === 22 && !checked) 
                setPagoInfo(prevInfo => { return { ...prevInfo, idMetodoPago: 0 }})
        }
    };

	const setCalculoAplicar = () => {

		const { error } = validatePagoInfo(documentosPendientes, saldoAFavor, pagoInfo);

        if(error) { cogoToast.error(error, toastConfig); return; }

        if (pagoInfo.calculoTipo === calculoTipos.manual) {

            if (!passwordAplicada)
                openPasswordModal(setAplicacionManual);
            else
                setAplicacionManual();

        }
        else setCalculoPrelacion();
 
	};

    const setAplicacionManual = () => {

        const importeDiff = pagoInfo.importe - documentosPendientes.reduce((total, documento) => total + documento.Saldo, 0);
        const saldoAFavorGenerado = importeDiff > 0 ? importeDiff : 0;
        setPagoInfo(prevInfo => { return { ...prevInfo, saldoAFavorGenerado } });

        setAplicacionEstado(aplicacionEstadoTipos.manual);
    };

    const setCalculoPrelacion = () => {

        const prelacionInfo = calcularPagoPrelacion2(documentosPendientes, pagoInfo);

        const { saldoAFavorGenerado, documentosOrdenados, headersInfo } = prelacionInfo;

		setPagoInfo(prevInfo => { return { ...prevInfo, saldoAFavorGenerado }});
		setDocumentosPendientes(documentosOrdenados);
		setAplicacionEstado(aplicacionEstadoTipos.prelacion);

        setHeadersInfo(headersInfo);
    };

	const onResetCalculo = () => {

		setAplicacionEstado(aplicacionEstadoTipos.inicial);
        setPagoInfo(prevInfo => { return {
            ...prevInfo, banco: "", numMovimiento: "", comentarios: ""
        }})
        resetCalculos();
	};

    const getAplicarCheckboxDisabled = (documento) => {

        const { Descuento, Saldo, Condonar } = documento;

        return aplicacionEstado !== aplicacionEstadoTipos.manual 
            || Descuento > 0 
            || (Saldo === 0 && Condonar !== condonarTipos.Restante);
    };

    const getAplicarInputDisabled = (documento) => {

        const { checked } = documento;

        return aplicacionEstado !== aplicacionEstadoTipos.manual || !checked;
    };

    const getCondonarSelectDataDisabled = (documento) => {
        
        const { checked, Saldo, Aplicado, Condonar, Descuento } = documento;

        if(aplicacionEstado === aplicacionEstadoTipos.inicial) return true;

        if(pagoInfo.importe >= saldoTotal) return true;

        if(aplicacionEstado === aplicacionEstadoTipos.prelacion) {

            return (!checked) || Descuento > 0
        } 
        else return (Saldo === Aplicado && !Condonar) 
            || Descuento > 0 
            || Condonar === condonarTipos.Previo
    };

    const getCondonarSelectHeaderOptions = () => headerOptions;

    const setCondonarSelectGetDataOptions =
        ({ Aplicados }, pagoImporte, saldoTotal) =>
            (documento) => {

        const { saldoPreCondonar, totalPreCondonar } = SetDocPagoData.getTotalesPreCondonar(documento);
        const { checked, Aplicado, Saldo, Importe, Recargos, Pagado, Condonar, Condonacion, PrevCondonacion } = documento;

        const otherSaldos = +(saldoTotal - Saldo).toFixed(2);
        const cantidadRemaining = +(saldoPreCondonar - (pagoImporte - otherSaldos)).toFixed(2);
        const maxApply = Math.min(cantidadRemaining, totalPreCondonar);

        const disablePrevio = PrevCondonacion === 0 
            || (PrevCondonacion > maxApply && Condonar !== condonarTipos.Previo);
        const disableRecargos = Recargos === 0
            || (Recargos > maxApply && Condonar !== condonarTipos.Recargos);
        const disableRestante = Saldo === Aplicado;

        if(!disablePrevio || disableRecargos || disableRestante) {
 
            const newDataOptions = condonarSelectOptions.map((option) => { return { ...option }});
            newDataOptions[condonarTipos.Previo].disabled = disablePrevio;
            newDataOptions[condonarTipos.Recargos].disabled = disableRecargos;
            newDataOptions[condonarTipos.Restante].disabled = disableRestante;

            return newDataOptions;
        }

        return condonarSelectOptions;
    };

    const getCondonarMode = () => {

        if(!enabledCondonar) return condonarModes.No;

        if(aplicacionEstado !== aplicacionEstadoTipos.inicial 
            && pagoInfo.saldoAFavorGenerado === 0) {

            return pagoInfo.idMetodoPago !== 22 ? condonarModes.Condonar : condonarModes.SoloCondonar;
        }

        return condonarModes.No;
    };

    const condonarMode = getCondonarMode();

    const DocumentsTableRow =
        <div className="row full table-apply-payment">
            <TablaDocumentosDePago
                loadingDocumentos={loadingDocumentos}
                documentosPendientes={documentosPendientes}
                aplicacionEstado={aplicacionEstado}
                headersInfo={headersInfo}
                condonarMode={condonarMode}
                aplicadosTotales={aplicadosTotales}
                tableStyleInfo={tableStyleInfo}
                tableUpdate={tableUpdate}
                getAplicarOptions={[
                    getAplicarCheckboxDisabled,
                    getAplicarInputDisabled
                ]}
                getCondonarSelectOptions={[
                    getCondonarSelectHeaderOptions, 
                    setCondonarSelectGetDataOptions(aplicadosTotales, pagoInfo.importe, documentosTotales.Saldos),
                    getCondonarSelectDataDisabled
                ]}
                documentosActions={documentosActions}
            />
        </div>;

	const pagoScreens = [
		<AplicarPagoForm
			unidad={unidad}
			metodosPago={metodosPagoFormat}
			documentosPendientes={documentosPendientes}
			saldoAFavor={saldoAFavor}
			loadingDocumentos={loadingDocumentos}
            documentosTotales={documentosTotales}
            aplicadosTotales={aplicadosTotales}
			aplicacionActiva={aplicacionEstado !== aplicacionEstadoTipos.inicial}
            enabledPrelacion={enabledPrelacion}
            enabledCondonar={enabledCondonar}
			pagoInfo={pagoInfo}
            saldoTotal={saldoTotal}
			setPagoInfo={setPagoInfo}
            onEnableCondonar={onEnableCondonar}
			onFechaChange={setRecargosYDescuentos}
			onSetCalculo={setCalculoAplicar}
			onResetCalculo={onResetCalculo}
            DocumentsTableRow={DocumentsTableRow}
		/>
	];

	const passwordScreens = [
		<PagoManualPasswordForm
			password={passwordText}
			setPassword={setPasswordText}
			onSubmit={loadSubmitPassword}
		/>
	];

	return (
		<>
			<SuperModal
				id={"newPayment"}
				size={"huge"}
				title={"Aplicar pago"}
				screens={pagoScreens}
				onSubmit={loadSubmitPago}
				loading={loadingPago}
				triggerCloseModal={onCloseModal}
			/>
			<SuperModal
				id={"passwordManual"}
				size={"small"}
				title={"Contraseña de pago manual"}
				lblSubmit={"Aceptar"}
				screens={passwordScreens}
				onSubmit={loadSubmitPassword}
				loading={loadingPasswordSubmit}
				triggerCloseModal={() => setPasswordText("")}
			/>
		</>
	)
};

const CondonarSelect = (props) => {

    const { value, isHeader, cantidad, options = [], disabled, autoFocusInput = true,
        onSelectDocCondonar, onAplicarCantidad } = props;

    const ingresarMode = value === condonarTipos.Cantidad;

    const currencyClasses = ["text-right"];

    currencyClasses.push(value !== condonarTipos.No && value !== condonarTipos.Previo ? "weight-bold" : "font-mini")

    if(ingresarMode) currencyClasses.push('input input-modals full checked-input weight-bold');

    const onChange = (e) => {

        const select = +e.target.value;
        
        if(select === condonarTipos.Cantidad && autoFocusInput) {
            waitForElement(".checked-input", e.currentTarget)
            .then((element) => element.focus());
        }
    }

    return (
        <div onChange={onChange} className='row condonar-select'>
            {!isHeader &&
                <div style={ingresarMode ? { width: 85 } : undefined}>
                    <CurrencyFormatInput
                        value={cantidad}
                        className={currencyClasses.join(" ")}
                        displayType={!ingresarMode ? "text" : "number"}
                        disabled={!ingresarMode}
                        onValueChange={(value) => onAplicarCantidad(value)}
                    />
                </div>
            }
            <select
                value={value}
                style={{ fontSize: 0 }}
                disabled={disabled}
                onChange={(e) => onSelectDocCondonar(+e.target.value)}
            >
                {options.map(({ id, option, disabled }, index) => 
                    <option style={{ fontSize: '0.9rem' }} key={index} value={id} disabled={disabled}>
                        {option}
                    </option>
                )}
            </select>
        </div>
    );
};

const PagoManualPasswordForm = (props) => {

	const { password, setPassword, onSubmit } = props;

	return (
		<div className="container">
			<div className="column full">
				<div className="row full">
					<div className="white-space-16"></div>
				</div>
				<div className="row full">
					<form autoComplete='off' className="input-form-content row full align-center" onSubmit={(e) => e.preventDefault()}>
						<div className="column label-big">
							<p>
								<b>Contraseña:</b>
							</p>
						</div>
						<div className="column full">
							<input
                                id=""
                                tabIndex="-1"
                                autoComplete="off"
								className="input input-modals"
								type="password"
								name="password"
                                hidden={false}
								onKeyDown={event => { if(event.key === "Enter") onSubmit(); }}
								value={password}
								onChange={(event) => setPassword(event.target.value)}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

const AplicarPagoForm = (props) => {

	const { 
		unidad, metodosPago, enabledPrelacion, enabledCondonar,
		aplicacionActiva, pagoInfo, saldoAFavor, loadingDocumentos, aplicadosTotales, documentosTotales, saldoTotal,
		setPagoInfo, onEnableCondonar, onFechaChange, onSetCalculo, onResetCalculo,
        DocumentsTableRow
	 } = props;

	const { fecha, idMetodoPago, importe, calculoTipo, banco, numMovimiento, comentarios, saldoAFavorGenerado } = pagoInfo;
    const { Saldos, Safgs } = documentosTotales;
    const { Condonados } = aplicadosTotales || { Condonados : 0 };

    const onChangeMetodoPago = (e) => setPagoInfo(prevInfo => {

        const idMetodoPago = +e.target.value;
        const { calculoTipo, importe } = idMetodoPago !== 22 ? 
            prevInfo 
            : { calculoTipo: calculoTipos.manual, importe: 0 };

        return {...prevInfo, idMetodoPago, calculoTipo, importe };
    });

	return (
		<div className="container">
			<div className="column full">
				<div className="white-space-16"></div>
				<div className="row full" style={{ justifyContent: 'space-between' }}>
					<label className="cont-table-fake width-20">
						<h3 className="tlt-table-fake">Vivienda</h3>
						<div className="cont-input-table-fake">
							<input
								type="text"
								placeholder="Vivienda"
								className="input-modals text-center"
								value={unidad}
								minLength='2'
								maxLength='4'
								disabled={true}
							/>
						</div>
					</label>
					<label className="cont-table-fake width-20">
						<h3 className="tlt-table-fake">Saldo a favor</h3>
						<div className="cont-input-table-fake align-end">
							{!loadingDocumentos ?
								<NumberFormat
									className="input-modals text-right"
									value={saldoAFavor}
									displayType={"number"}
									placeholder={"$"}
									thousandSeparator={true}
									prefix={"$"}
									decimalScale={2}
									fixedDecimalScale={true}
									disabled={true}
								/>
								:
								<input
									className="input-modals text-right"
									value={"--"}
									disabled={true}
								/>
							}
						</div>
					</label>
				</div>
				<div className="white-space-16"></div>
				<div className="column" style={{ justifyContent: 'space-between' }} >
					<div className="row full" style={{ justifyContent: 'space-between' }}>
						<label className="cont-table-fake width-20" >
							<h3 className="tlt-table-fake">Fecha</h3>
							<div className="cont-input-table-fake" >
								<DatePicker
									className="input input-modals"
									name="fechaLinea"
									selected={fecha}
									disabled={aplicacionActiva}
									dateFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
									placeholderText="Seleccionar fecha"
									locale="es"
									onChange={onFechaChange}
								/>
							</div>
						</label>
						<label className="cont-table-fake width-20" style={{ position: "relative"}}>
                            <CheckBox 
                                title={"Condonar"}
                                style={{ position: "absolute", top: -34 }}
                                disabled={aplicacionActiva}
                                checked={enabledCondonar}
                                onChecked={(e) => onEnableCondonar(e.target.checked)}
                            />
							<h3 className="tlt-table-fake">Formas de pago</h3>
							<div className="cont-input-table-fake" >
								<select
									className="input-modals"
									name="formaPagoLinea"
									disabled={aplicacionActiva}
									value={idMetodoPago}
									onChange={onChangeMetodoPago}
								>
									<option value="0">Seleccione</option>
									{metodosPago.map((metodo, index) => (
										<option
											key={index}
											value={metodo.Id_FormaPago}
											disabled={metodo.disabled}
										>
											{metodo.Descripcion}
										</option>
									))}
								</select>
							</div>
						</label>
						<label className="cont-table-fake width-20" >
							<h3 className="tlt-table-fake">Importe</h3>
							<div className="cont-input-table-fake" >
                                <CurrencyFormatInput
                                    value={importe}
                                    className={"text-right input input-modals"}
                                    displayType={"number"}
                                    disabled={aplicacionActiva || idMetodoPago === 22}
                                    onValueChange={!aplicacionActiva ? (value) => setPagoInfo(prevInfo => { return { ...prevInfo, importe: Number(value) }}) : undefined}
                                />
							</div>
						</label>
						<div className="column" style={{ justifyContent: 'space-between' }}>
							<label className="container-radio" style={{ pointerEvents: !aplicacionActiva && enabledPrelacion ? "all" : "none" }}>
                                <input
									type="radio"
									name="prelacion"
									checked={calculoTipo === calculoTipos.prelacion}
									onChange={() => setPagoInfo(prevInfo => { 
										return { ...prevInfo, calculoTipo: calculoTipos.prelacion }
									})}
									disabled={aplicacionActiva || !enabledPrelacion || idMetodoPago === 22}
								/>
                                <p>Prelación</p>
								<span className="checkmarks"></span>
							</label>
							<label className="container-radio" style={{ pointerEvents: !aplicacionActiva ? "all" : "none" }}>
								<input
									type="radio"
									name="manual"
									checked={calculoTipo === calculoTipos.manual}
									onChange={() => setPagoInfo(prevInfo => { 
										return { ...prevInfo, calculoTipo: calculoTipos.manual }
									})}
									disabled={aplicacionActiva}
								/>
								<p>Manual</p>
								<span className="checkmarks"></span>
							</label>
						</div>
						<div className="btn-position" style={{ justifyContent: "flex-end", minWidth: 110 }}>
							<button
								type="button"
								className="btn btn-primary btn-red-table color-white height-30"
								onClick={!aplicacionActiva ? onSetCalculo : onResetCalculo}
							>
								{!aplicacionActiva ? "Aplicar" : "No Aplicar"}
							</button>
						</div>
					</div>
					<div className="white-space-16"></div>
                    {DocumentsTableRow}
					<div className="white-space-16"></div>
                    <div className='row' style={{ gap: 8 }}>
                        <div className='row full align-start'>
                            <div className="row full align-start">
                                <label className="cont-table-fake width-50" style={{ marginRight: 15 }}>
                                    <h3 className="tlt-table-fake">Banco</h3>
                                    <div className="cont-input-table-fake" >
                                        <input
                                            type="text"
                                            className="input-modals"
                                            minLength='2'
                                            disabled={!aplicacionActiva || (aplicacionActiva && parseInt(idMetodoPago) === -1)}
                                            value={parseInt(idMetodoPago) === -1 ? "Saldo a favor" : banco}
                                            onChange={(event) => setPagoInfo(prevInfo => { return { ...prevInfo, banco: event.target.value } })}
                                        />
                                    </div>
                                </label>
                                <label className="cont-table-fake width-50" style={{ marginRight: 15 }}>
                                    <h3 className="tlt-table-fake" >{'Nº Movimiento'}</h3>
                                    <div className="cont-input-table-fake" >
                                        <input
                                            type="text"
                                            placeholder=""
                                            className="input-modals"
                                            minLength='2'
                                            disabled={!aplicacionActiva || (aplicacionActiva && parseInt(idMetodoPago) === -1)}
                                            value={parseInt(idMetodoPago) === -1 ? "Saldo a favor" : numMovimiento}
                                            onChange={(event) => setPagoInfo(prevInfo => { return { ...prevInfo, numMovimiento: event.target.value } })}
                                        />
                                    </div>
                                </label>
                                <label className="cont-table-fake width-50">
                                    <h3 className="tlt-table-fake">Comentarios</h3>
                                    <div className="cont-input-table-fake" >
                                        <input
                                            type="text"
                                            className="input-modals"
                                            minLength='2'
                                            maxLength={128}
                                            disabled={!aplicacionActiva}
                                            value={comentarios}
                                            onChange={(event) => setPagoInfo(prevInfo => { return { ...prevInfo, comentarios: event.target.value } })}
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className='column full justify-end align-start'>
                            {aplicacionActiva && (saldoAFavorGenerado > 0 || Safgs > 0) &&
                                <div className="row full align-center justify-end" style={{ gap: 8 }}>
                                    <h5 style={{ whiteSpace: "nowrap" }}>Saldo a favor generado: </h5>
                                    <NumberFormat
                                        className="row full align-center justify-end"
                                        style={{ width: "100%" }}
                                        color='green'
                                        value={saldoAFavorGenerado || Safgs}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </div>
                            }
                            {aplicacionActiva && (saldoAFavorGenerado > 0 || Safgs > 0) && saldoAFavor > 0 &&
                                <div className="row full align-center justify-end" style={{ gap: 8 }}>
                                    <h5 style={{ whiteSpace: "nowrap" }}>Nuevo saldo a favor: </h5>
                                    <NumberFormat
                                        className="row full align-center justify-end"
                                        style={{ width: "100%" }}
                                        value={saldoAFavor + (saldoAFavorGenerado || Safgs)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </div>
                            } 
                            {Condonados > 0 &&
                                <div className="row full align-center justify-end" style={{ gap: 8 }}>
                                    <h5 style={{ whiteSpace: "nowrap" }}>A condonar: </h5>
                                    <NumberFormat
                                        className="row full align-center justify-end"
                                        style={{ width: "100%" }}
                                        value={Condonados}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </div>
                            }
                            {!loadingDocumentos &&
                                <div className="row full justify-end align-center" style={{ gap: 8 }}>
                                    <h5 style={{ whiteSpace: "nowrap" }}>Total: </h5>
                                    <NumberFormat
                                        className="row full align-center justify-end"
                                        style={{ width: "100%" }}
                                        value={Saldos}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const LastTd = (props) => {

    const { documento, aplicar, index,
        onSetDocCheck, onAplicar, onAplicarChecked,
        onAplicarCondonacion, onSelectDocCondonar,
        checkBoxDisabled, inputDisabled,
        condonarOptions, condonarDisabled
    } = props;

    const { checked: AplicadoChecked, Aplicado, Condonacion, Condonar, Descuento, Saldo } = documento;

    const onAplicarDoubleClick = (e) => {

        const checkbox = e.currentTarget.querySelector("input[type='checkbox']");
        checkbox.click();
    };

    return (
        <td
            className={"text-right color-red"}
            onClick={onAplicarChecked}
            onDoubleClick={aplicar && !checkBoxDisabled && !AplicadoChecked ? onAplicarDoubleClick : undefined}
        >
            <div style={{ display: "flex", minWidth: !inputDisabled && aplicar ? 140 : undefined, flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 8 }}>
                {aplicar ?
                    <>
                        <CurrencyFormatInput
                            value={Aplicado}
                            className={`text-right
                                ${inputDisabled ? "" : " input input-modals full checked-input "} 
                                ${AplicadoChecked ? "weight-bold" : "font-mini"}`
                            }
                            displayType={inputDisabled ? "text" : "number"}
                            disabled={inputDisabled}
                            onValueChange={(value) => onAplicar(index, value)}
                        />
                        <CheckBox
                            disabled={checkBoxDisabled}
                            checked={AplicadoChecked}
                            onChecked={(e) => onSetDocCheck(index, e.target.checked)}
                        />
                    </>
                    :
                    <CondonarSelect
                        options={condonarOptions}
                        value={Condonar}
                        cantidad={Condonacion}
                        disabled={condonarDisabled}
                        onSelectDocCondonar={(value) => onSelectDocCondonar(index, value)}
                        onAplicarCantidad={(value) => onAplicarCondonacion(index, value)}
                    />
                }
            </div>
        </td>
    );
};

const getAplicacionEstilo = (estado) =>
    estado === aplicacionEstadoTipos.inicial ? 'inactiva' : 'pago-aplicado';

const TablaDocumentosDePago = (props) => {

	const { 
        loadingDocumentos, documentosPendientes, aplicacionEstado, condonarMode, aplicadosTotales,
        documentosActions, getAplicarOptions, getCondonarSelectOptions,
        headersInfo, tableStyleInfo, tableUpdate
    } = props;

    const [getAplicarCheckboxDisabled, getAplicarInputDisabled] = getAplicarOptions;

    const [getHeaderOptions, getDataOptions, getDataDisabled] = getCondonarSelectOptions;

    const { checkDocAplicar, setDocAplicarCantidad, 
        setDocCondonarCantidad, selectDocCondonar } = documentosActions;

    const { headersInfoMaxWidth, enableConceptShadow } = tableStyleInfo;

    const tableRef = useRef();

    const [animationTriggered, setAnimationTriggered] = useState(false);

    useLayoutEffect(() => {
        
        if(loadingDocumentos) return;

        setAnimationTriggered(false);
        if (tableUpdate) window.requestAnimationFrame(() => setAnimationTriggered(true));

    }, [tableUpdate, loadingDocumentos]);

    useLayoutEffect(() => {

        if (aplicacionEstado !== aplicacionEstadoTipos.inicial) 
            setAnimationTriggered(false);

    }, [aplicacionEstado]);

    const onAplicar = (index, value) => {

        if(aplicacionEstado !== aplicacionEstadoTipos.manual) return;

        const parsedValue = parseFloat(value);

        if(documentosPendientes[index].Aplicado === parsedValue) return;

        setDocAplicarCantidad && setDocAplicarCantidad(index, parsedValue);
    };

    const onAplicarCondonacion = (index, value) => {

        if(aplicacionEstado === aplicacionEstadoTipos.inicial) return;

        const parsedValue = parseFloat(value);

        if(documentosPendientes[index].Condonacion === parsedValue) return;

        setDocCondonarCantidad && setDocCondonarCantidad(index, parsedValue);
    };

    const onAplicarChecked = (e) => {

        if(e.target.checked) {
            waitForElement(".checked-input", e.currentTarget)
                 .then((element) => element.focus());
        }
    };

    const tableClassName = "full " + (getAplicacionEstilo(aplicacionEstado)) + (enableConceptShadow ? " enable-shadow" : "");
    const tableRowClassName = (doc) => animationTriggered && (doc.Descuento > 0 || doc.Recargos > 0) ? "cell-update" : "";

    console.log("Aplicados totales: ", aplicadosTotales);

	return (
        <table ref={tableRef} className={tableClassName} style={{ zIndex: 0 }}>
			<thead>
				<tr>
					<th>Concepto</th>
                    <th>Mes</th>
					<th>N° Folio</th>
					<th>Importe</th>
					<th>Dto. PP</th>
					<th>Recargos</th>
                    {(condonarMode === condonarModes.Condonar || true) &&
                        <th className="weight-bold text-center" /*style={{ paddingRight: "0.4rem" }}*/>
                            <div className='row justify-center'>
                                Condonar
                                {/* <CondonarSelect 
                                    isHeader={true}
                                    options={getHeaderOptions()}
                                    onOptionChange={() => {}}
                                /> */}
                            </div>
                        </th>
                    }
					<th className="weight-bold text-center">Total</th>
                    <th className="weight-bold text-center">Pagado</th>
					<th className="weight-bold text-center">Saldo</th>
                    <th className="weight-bold text-center">
                        {condonarMode !== condonarModes.SoloCondonar ? "Aplicar" : "Condonar"}
                    </th>
				</tr>
			</thead>
			<tbody>
				{loadingDocumentos ?
					<tr>
						<td colSpan={10} className="text-center" style={{ borderRight: "unset", boxShadow: "unset" }}>
							<i className="fas fa-spinner fa-spin"></i>
						</td>
					</tr>
					: documentosPendientes.length === 0 ?
						<tr>
							<td colSpan={10} className="text-center">
								Sin datos que mostrar
							</td>
						</tr>
						:
                        <>
                            {documentosPendientes.map((doc, index) => {

                                const headerTitle = headersInfo[index];
                                const disableCheckbox = getAplicarCheckboxDisabled(doc);
                                const disableInput = getAplicarInputDisabled(doc);
                                const condonarOptions = getDataOptions(doc);
                                const disableSelect = getDataDisabled(doc);

                                return (<React.Fragment key={index}>
                                    {headerTitle && aplicacionEstado === aplicacionEstadoTipos.prelacion &&
                                        <tr>
                                            <td className='documents-header' colSpan={11}>
                                                <div style={{ maxWidth: headersInfoMaxWidth }}>
                                                    {headerTitle}
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    <tr className={tableRowClassName(doc)}>
                                        <td style={{ maxWidth: 250 }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", flexWrap: "nowrap", gap: 4 }}>
                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                                    {doc.Concepto}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="font-mini" style={{ whiteSpace: "nowrap" }}>
                                            {moment(doc.Fecha, "MM-YYYY").locale('es').format("MMM YYYY").replace(".", "")}
                                        </td>
                                        <td className="font-mini">{doc.NumFolio}</td>
                                        <td className="text-right font-mini">
                                            <NumberFormat
                                                className="font-mini"
                                                value={doc.Importe}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        <td className="text-right font-mini">
                                            <NumberFormat
                                                className="font-mini"
                                                value={doc.Descuento}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        <td className="text-right font-mini">
                                            <NumberFormat
                                                className="font-mini"
                                                value={doc.Recargos}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        {(condonarMode === condonarModes.Condonar || true) &&
                                            <td 
                                                className="text-right color-red" 
                                                style={{ paddingRight: '0.4rem' }}
                                            >
                                                <CondonarSelect 
                                                    options={condonarOptions}
                                                    value={doc.Condonar}
                                                    cantidad={doc.Condonacion}
                                                    disabled={disableSelect}
                                                    onSelectDocCondonar={(value) => selectDocCondonar(index, value)}
                                                    onAplicarCantidad={(value) => onAplicarCondonacion(index, value)}
                                                />
                                            </td>
                                        }
                                        <td className="text-right">
                                            <NumberFormat
                                                className="font-mini"
                                                value={doc.Total}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        <td className="text-right">
                                            <NumberFormat
                                                className="font-mini"
                                                value={doc.Pagado}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        <td className="text-right">
                                            <NumberFormat
                                                className="font-mini"
                                                value={doc.Saldo}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        <LastTd
                                            index={index}
                                            documento={doc}
                                            aplicacionEstado={aplicacionEstado}
                                            aplicar={condonarMode !== condonarModes.SoloCondonar}
                                            checkBoxDisabled={disableCheckbox}
                                            inputDisabled={disableInput}
                                            condonarOptions={condonarOptions}
                                            condonarDisabled={disableSelect}
                                            onSetDocCheck={checkDocAplicar}
                                            onAplicar={onAplicar}
                                            onAplicarChecked={onAplicarChecked}
                                            onSelectDocCondonar={selectDocCondonar}
                                            onAplicarCondonacion={onAplicarCondonacion}
                                        />
                                    </tr>
                                </React.Fragment>
                                )
                            })}
                            {aplicacionEstado !== aplicacionEstadoTipos.inicial &&
                                <tr>
                                    <td>Totales</td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right">
                                        <NumberFormat
                                            className="font-mini"
                                            value={aplicadosTotales.Importes}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    <td className="text-right">
                                        <NumberFormat
                                            className="font-mini"
                                            value={aplicadosTotales.Descuentos}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    <td className="text-right">
                                        <NumberFormat
                                            className="font-mini"
                                            value={aplicadosTotales.Recargos}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    {(condonarMode === condonarModes.Condonar || true) &&
                                        <td className="text-right" style={{ paddingInline: 16 }}>
                                            <NumberFormat
                                                className="font-mini"
                                                value={aplicadosTotales.Condonados}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                    }
                                    <td className="text-right">
                                        <NumberFormat
                                            className="font-mini"
                                            value={aplicadosTotales.Totales}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    <td className="text-right">
                                        <NumberFormat
                                            className="font-mini"
                                            value={aplicadosTotales.Pagados}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    <td className="text-right">
                                        <NumberFormat
                                            className="font-mini"
                                            value={aplicadosTotales.Saldos}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                    <td className="text-right" style={{ paddingInline: 16 }}>
                                        <NumberFormat
                                            className="weight-bold"
                                            value={
                                                condonarMode !== condonarModes.SoloCondonar ? 
                                                    aplicadosTotales.Aplicados : aplicadosTotales.Condonados
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />
                                    </td>
                                </tr>
                            }
                        </>
                }
            </tbody>
        </table>
	);
}